@import '../../../../../sass/utilites/variables';
@import '../../../../../sass/utilites/mixins';

.controls {
	@include font-size($font-size-sm);
}

.control {
	transition-property: color;
	transition-duration: #{$transition-duration}s;
	position: relative;

	color: get($colors, dark);
	z-index: get($index, default);
	cursor: pointer;
	user-select: none;
	@include media-breakpoint-up(xl) {
		padding: rem(10px) rem(25px) rem(8px);
		&:before, &:after {
			@include pseudo;
			transition-property: background-color;
			transition-duration: #{$transition-duration}s;
			top: 2.5%;
			width: 70%;
			height: 97.5%;
			background-color: $gray-200;
			z-index: get($index, negative);
		}
		&:before {
			left: 2%;
			border-radius: $border-radius-xl 0 0 0;
			transform: skewX(-15deg);
		}
		&:after {
			right: 2%;
			border-radius: 0 $border-radius-xl 0 0;
			transform: skewX(15deg);
		}
	}
	@include media-breakpoint-down(xl) {
		padding: rem(8px) $grid-gutter-width / 2;
	}
}

.content {
	position: relative;
	z-index: get($index, sm);
}

.select {
	@include font-size($font-size-extra-small);
	position: relative;
	min-width: rem(130px);
	&Button {
		width: 100%;
		font-size: inherit;
		padding: rem(2px) rem(15px);
		min-width: initial;
		svg {
			@include transition(transform, #{$transition-duration}s, ease-in-out);
			width: rem(10px);
			height: rem(10px);
		}
	}
	&List {
		transition-property: visibility, opacity, transform;
		transition-duration: #{$transition-duration}s;
		transition-timing-function: ease-in-out;
		position: absolute;
		background-color: get($colors, white);
		z-index: get($index, sm);
		width: 100%;
		top: calc(100% + #{rem(8px)});
		border-radius: $border-radius-xl;
		overflow: hidden;
		box-shadow: get($shadows, small);
		opacity: 0;
		visibility: hidden;
		transform: translateY(5%);
	}
}



:global {
	:local(.control) {
		&.is-active {
			color: get($colors, black);
			cursor: default;
			z-index: get($index, sm);
			@include media-breakpoint-up(xl) {
				&:before, &:after {
					background-color: get($colors, white);
					z-index: get($index, default);
				}
			}
			@include media-breakpoint-down(xl) {
				background-color: get($colors, primary);
				color: get($colors, white);
			}
		}
	}
	:local(.selectList) {
		&.is-shown {
			opacity: 1;
			visibility: visible;
			transform: translateY(0);
		}
	}
	:local(.selectButton) {
		&.is-active {
			svg {
				transform: rotate(180deg);
			}
		}
	}
}
