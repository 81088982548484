@import '../../../../sass/utilites/variables';
@import '../../../../sass/utilites/mixins';

$arrow-icon-size-lg: rem(22px);
$arrow-icon-size-sm: rem(15px);
$icon-size:          rem(45px);

.root {
	transition-duration: #{$transition-duration}s;
	transition-property: color, border-color;
	display: inline-block;
}

.icon, .simple {
	color: get($colors, dark);
	&:hover {
		color: get($colors, primary);
	}
}

.icon {
	border-radius: 50%;
	border: $border-width solid lighten(get($colors, dark), 15%);
	display: inline-flex;
	align-items: center;
	justify-content: center;
	@include media-breakpoint-up(xl) {
		width: $icon-size;
		height: $icon-size;
	}
	@include media-breakpoint-down(xl) {
		width: $icon-size * .75;
		height: $icon-size * .75;
		svg {
			max-width: 50%;
			max-height: 50%;
		}
	}
	&:hover {
		border-color: get($colors, primary);
	}
}

.simple {

	@include media-breakpoint-up(xl) {
		@include font-size($font-size-sm);
		font-weight: 500;
	}
	@include media-breakpoint-down(xl) {
		@include font-size($font-size-m);
	}
}

.arrow {
	position: relative;
	color: get($colors, secondary);

	display: inline-flex;
	align-items: center;
	min-height: $arrow-icon-size-lg;
	@include media-breakpoint-down(lg) {
		@include font-size($font-size-m)
	}
	@include media-breakpoint-up(sm) {
		padding-right: $arrow-icon-size-lg * 1.5;
	}
	@include media-breakpoint-down(sm) {
		@include font-size($font-size-extra-small);
		font-weight: 500;
		border: $border-width solid get($colors, primary);
		padding: rem(8px) rem(15px);
		border-radius: $border-radius-pill;
	}
	&Circle {
		@include transition(border-color);

		@include media-breakpoint-up(sm) {
			position: absolute;
			right: 0;
			top: 2px;
			border: $border-width solid rgba(get($colors, dark), .4);
			border-radius: 50%;
			width: $arrow-icon-size-lg;
			height: $arrow-icon-size-lg;
		}
		@include media-breakpoint-down(sm) {
			width: $arrow-icon-size-sm;
			height: $arrow-icon-size-sm;
		}
	}
	svg {
		@include media-breakpoint-up(sm) {
			@include center('both');
		}
		@include media-breakpoint-down(sm) {
			margin-top: -3px;
			margin-left: 3px;
		}
	}
	&:hover {
		color: get($colors, primary);
		span {
			border-color: get($colors, primary);
		}
	}
}
