@import '../../../../sass/utilites/variables';

.container {
	display: inline-flex;
	color: $gray-500;
}

.trigger {
	cursor: help;
	svg {
		width: rem(22px);
		height: rem(22px);
	}
}
