@import '../../../../sass/utilites/variables';
@import '../../../../sass/utilites/mixins';

.amount {
	@include media-breakpoint-up(xl) {
		@include font-size(40px);
	}
	@include media-breakpoint-between(sm, xl) {
		@include font-size(24px);
	}
	@include media-breakpoint-down(sm) {
		@include font-size(36px);
	}
	max-width: rem(240px);
	margin-left: auto;
	margin-right: auto;
	input {
		text-align: center;
		font-weight: 700;
		@include media-breakpoint-up(xl) {
			padding-top: rem(14px);
			padding-bottom: rem(14px);
		}
		@include media-breakpoint-between(sm, xl) {
			padding-top: rem(8px);
			padding-bottom: rem(8px);
		}
	}
}

.title {
	@include media-breakpoint-between(sm, xl) {
		@include font-size($font-size-m)
	}
	@include media-breakpoint-down(sm) {
		@include font-size($font-size-sm)
	}
}



.phone {
	text-align: center;
	&Title {
		@include font-size($font-size-m)
	}
	&Number {
		@include font-size($font-size-md);
		font-weight: 400;
	}
}

.loaded {
	position: absolute;
	left: $spacer * 2;
	top: $spacer * 2;
}

.withdraw {
	:global(.modal-body) {
		@include transition(opacity)
	}
}

.isLoading {
	:global(.modal-body) {
		opacity: .5;
		pointer-events: none;
		cursor: default;
	}
}

