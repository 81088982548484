@import '../../../../../sass/utilites/variables';


.table {
	padding-top: rem(10px);
	padding-bottom: rem(10px);
	td {
		vertical-align: middle;
		&:first-of-type {
			@include media-breakpoint-up(xl) {
				@include font-size($font-size-sm)
			}
			@include media-breakpoint-down(xl) {
				@include font-size($font-size-m)
			}
		}
		&:last-of-type {
			white-space: nowrap;
			@include media-breakpoint-up(xl) {
				@include font-size($font-size-md)
			}
			@include media-breakpoint-down(sm) {
				@include font-size($font-size-m)
			}
		}
	}
}

.project {
	padding: rem(18px);
	border-radius: $border-radius-xxl;
	:global {
		.key {
			@include font-size($font-size-sm);
		}
		.value {
			@include font-size($font-size-md);
		}
	}
}
