@import '../../../../../sass/utilites/variables';
@import '../../../../../sass/utilites/mixins';

.label {
	@include font-size($font-size-sm);
	font-weight: 500;
}

.account {
	transition-duration: #{$transition-duration}s;
	transition-property: background-color, color, border-color;
	border: $border-radius solid $border-color;
	border-radius: $border-radius-xl;
	padding: $grid-gutter-width / 2;
	cursor: pointer;
	width: 100%;
	text-align: left;
	&:hover {
		background-color: get($colors, primary);
		color: get($colors, white);
		border-color: get($colors, primary);
	}
}

.value {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: block;
}

.bik {
	text-align: center;
}

.accountNumber {
	text-align: left;
	direction: rtl;
}

// bankName

