@import '../../../../../../../sass/utilites/variables';

.graphic {
	tbody {
		tr {
			&:last-of-type {
				font-weight: 500;
				border-top: $border-width solid $gray-200;
			}
			&:not(:last-of-type) {
				border-bottom: 0 none;
			}
		}
	}
}

.document {
	background-color: $gray-400;
	border-radius: $border-radius-xl;
	padding: rem(16px);
	position: relative;
	min-height: rem(70px);
}
