@import '../../../../sass/utilites/variables';

$toggler-border-width: $border-width * 4;

.root {
	@include font-size($font-size-sm);
	font-weight: 500;
	text-align: center;
	position: relative;
	overflow: hidden;
	&:after {
		transition-property: left, right;
		transition-duration: #{$transition-duration * 1.5}s;
		transition-timing-function: cubic-bezier(.25,.75,.5,1.25);
		@include pseudo;
		left: 0;
		right: 50%;
		bottom: 0;
		height: $toggler-border-width;
		background-color: get($colors, primary);
	}
}

.control {
	text-align: center;
	display: block;
	border-bottom: $toggler-border-width solid $border-color;
	color: get($colors, dark);
	padding: rem(5px);
	user-select: none;
	cursor: pointer;
}

:global {
	:local(.root) {
		&.is-active {
			&:after {
				left: 50%;
				right: 0;
			}
		}
	}
	:local(.control) {
		&.is-active {
			//border-bottom-color: get($colors, primary);
			cursor: default;
			color: get($colors, primary);
		}
	}
}
