@import '../../../../sass/utilites/variables';
@import '../../../../sass/utilites/mixins';

$input-size-xl: rem(55px);
$input-size-md: rem(44px);


.list {
	display: flex;
	flex-wrap: nowrap;
}

.input {
	@include font-size($font-size-sm);
	border: $border-width solid $input-border-color;
	border-radius: $border-radius-xl;
	text-align: center;
	@include media-breakpoint-up(xl) {
		width: $input-size-xl;
		height: $input-size-xl;
	}
	@include media-breakpoint-down(xl) {
		width: $input-size-md;
		height: $input-size-md;
	}
}

.item {
	& + .item {
		margin-left: rem(15px);
	}
}

.error {
	position: absolute;
	left: 0;
	bottom: 0;
}

.header {
	min-height: rem(70px);
}

.spinner {
	position: absolute;
	left: -$spinner-width-sm;
	top: -$spinner-height-sm;
}
