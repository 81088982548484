.#{$rt-namespace}__close-button {
	color: #fff;
	cursor: pointer;
	opacity: 0.7;
	transition: 0.3s ease;
	align-self: flex-start;
	width: rem(30px);
	height: rem(30px);
	padding: rem(5px);
	&--light {
		color: #000;
		opacity: 0.3;
	}

	svg {
		fill: currentColor;
		vertical-align: top;
	}

	&:hover, &:focus {
		opacity: 1;
	}
}
