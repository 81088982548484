@import '../../../../sass/utilites/variables';
@import '../../../../sass/utilites/mixins';

.dialog {
	:global {
		.modal {
			&-header {
				padding-top: $grid-gutter-width;
				padding-bottom: $grid-gutter-width;
				min-height: initial;
			}
			&-body {
				@include font-size($font-size-sm);
				padding-left: $grid-gutter-width;
				padding-right: $grid-gutter-width;
				padding-bottom: $grid-gutter-width;
			}
		}
	}
}

:global {
	:local(.dialog) {
		&.is-wide {
			@include media-breakpoint-up(lg) {
				max-width: rem(990px);
			}
			@include media-breakpoint-between(sm, lg) {
				max-width: 90%;
			}
		}
	}
}

.documents {
	min-height: rem(200px);
	position: relative;
}
