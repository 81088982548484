@import "../../../../sass/utilites/variables";

.wrapper {
	&.pending {
		color: get($colors, yellow);
	}

	&.success {
		color: get($colors, jade);
	}
}
