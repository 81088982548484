@import '../../utilites/variables';
@import '../../utilites/mixins';

.#{$rt-namespace}__toast {
  position: relative;
  min-height: var(--toastify-toast-min-height);
  margin-bottom: 1rem;
  padding: $toastify-padding;
  border-radius: $border-radius-sm;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  max-height: var(--toastify-toast-max-height);
  overflow: hidden;
  font-family: $body-font-family;
  cursor: pointer;
  direction: ltr;
	strong, b {
		font-weight: 500;
	}
  &--rtl {
    direction: rtl;
  }
  &-body {
	  @include font-size($font-size-sm);
    margin: auto 0;
    flex: 1 1 auto;
    padding: $toastify-padding / 2;
    display: flex;
    align-items: flex-start;
  }
  &-icon {
    margin-inline-end: $toastify-padding;
    width: 20px;
    flex-shrink: 0;
    display: flex;
  }
}

.#{$rt-namespace}--animate {
  animation-fill-mode: both;
  animation-duration: 0.7s;
}

.#{$rt-namespace}--animate-icon {
  animation-fill-mode: both;
  animation-duration: 0.3s;
}

@media #{$rt-mobile} {
  .#{$rt-namespace}__toast {
    margin-bottom: 0;
    border-radius: 0;
  }
}
