@import '../../../../../../../sass/utilites/variables';
@import '../../../../../../../sass/utilites/mixins';

.statement {

	border-radius: $border-radius-xl;
	padding: rem(16px);
	display: block;
	width: 100%;
	max-width: rem(400px);
	@include media-breakpoint-up(sm) {
		background-color: $gray-400;
	}
	@include media-breakpoint-down(sm) {
		background-color: get($colors, white);
	}
	&Icon {
		display: flex;

		color: get($colors, primary);
		background-color: rgba(get($colors, primary), .12);
		border-radius: $border-radius-xl;
		@include media-breakpoint-up(xl) {
			width: rem(55px);
			height: rem(55px);
		}
		@include media-breakpoint-down(xl) {
			width: rem(40px);
			height: rem(40px);
		}
		svg {
			width: 50%;
			height: 50%;
			margin: auto;
		}
	}
	&Title {
		@include media-breakpoint-down(sm) {
			@include font-size($font-size-sm)
		}
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		padding-right: rem(20px);
	}
}
