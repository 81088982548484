@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

@import '../../../../sass/utilites/variables';

.wrap {
	@include font-size($font-size-m);
	border-radius: $border-radius-xxl;

	@include media-breakpoint-up(sm) {
		background-color: $gray-300;
		padding: rem(20px) rem(30px);
	}
	@include media-breakpoint-down(sm) {
		background-color: $white;
	}
}

:global {
	:local(.wrap) {
		&.is-horizontally-scrolled {
			width: calc(100% + #{$grid-gutter-width / 2});
			margin-right: - $grid-gutter-width / 2;
			@include media-breakpoint-between(sm, lg) {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
			@include media-breakpoint-down(sm) {
				border-radius: 0;
			}
			overflow: auto;
			table {
				width: rem(1000px);
			}
		}
	}
}

.main {
	width: 100%;
	table {
		table-layout: fixed;
	}
	td {
		@include media-breakpoint-up(lg) {
			padding-top: rem(20px);
			padding-bottom: rem(20px);
		}
		@include media-breakpoint-down(lg) {
			padding-top: rem(10px);
			padding-bottom: rem(10px);
		}
	}
	thead {
		border-bottom: $border-width solid $gray-200;
		th {
			font-weight: 500;
			padding-bottom: rem(20px);
		}
	}
	tbody {
		tr {
			vertical-align: top;
			&:not(:last-of-type) {
				border-bottom: $border-width solid $gray-200;
			}
		}
	}
}

.simple {
	td {
		@include media-breakpoint-up(lg) {
			&:first-of-type {
				width: 75%;
			}
			&:last-of-type {
				width: 25%;
			}
		}
		&:last-of-type {
			font-weight: 700;
			text-align: right;
		}
	}
}
