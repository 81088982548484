@import '../../../../../sass/utilites/variables';
@import '../../../../../sass/utilites/mixins';

.box {
	@include transition(outline-color);
	background-color: $gray-400;
	border-radius: $border-radius-xxl;

	outline: $border-width solid transparent;
	&:hover {
		outline-color: get($colors, primary);
	}
}

.title {
	@include media-breakpoint-up(lg) {
		font-weight: 500;
	}
	@include media-breakpoint-down(lg) {
		@include font-size($font-size-sm)
	}
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
