@import '../../../../../sass/utilites/variables';

:global {
	.modal-dialog {
		&.modal-dialog-video {
			width: 50vw;
			max-width: 90vw;
			.modal-content {
				border-radius: 0;
				background-color: $body-bg;
				box-shadow: 0 0 $spacer 0 rgba(0,0,0, .15);
			}
		}
	}
}

$size-close: rem(50px);

.close {
	width: $size-close;
	height: $size-close;
	right: -$size-close;
	top: -$size-close;
	position: absolute;
	color: get($colors, white);
	z-index: get($index, default);
	padding: rem(18px);
	svg {
		width: 100%;
		height: 100%;
	}
}


