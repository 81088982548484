@import '../../../../../sass/utilites/variables';
@import '../../../../../sass/utilites/mixins';


.founder {
  @include media-breakpoint-up(lg) {
    padding-left: 15%;
  }

  &Image {
    border-radius: $border-radius-xxl;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      width: rem(260px);
    }
    @include media-breakpoint-down(lg) {
      width: rem(120px);
    }
  }

  &Name {
    @include font-size($font-size-md);
    font-weight: 500;
    margin-bottom: rem(5px);
  }

  &Post {
    @include font-size($font-size-sm);
    color: get($colors, dark);
  }
}

.description {
  line-height: $line-height-md;
  @include media-breakpoint-down(xl) {
    color: get($colors, dark);
  }
}

.document {
  @include transition(outline-color);
  background-color: $gray-400;
  border-radius: $border-radius-xxl;
  font-weight: 500;
  outline: $border-width solid transparent;

  &:hover {
    outline-color: get($colors, primary);
  }
}

.infoSection {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
}

.socialGroup {
  display: grid;
  grid-template-columns: auto 100px;
  gap: 15px;

  @media screen and (max-width: 1399px) {
    grid-template-columns: auto;
    align-items: center;
  }
}

.social {
  @media screen and (max-width: 767px) {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
  }
}
