@import '../../../../sass/utilites/variables';
@import '../../../../sass/utilites/mixins';

.wrap {
	@include media-breakpoint-up(md) {
		@include font-size($font-size-m);
	}
	@include media-breakpoint-down(md) {
		@include font-size($font-size-extra-small-up);
	}
	color: get($colors, dark);
	:global {
		.progress-bar {
			border-radius: $progress-border-radius;
			&.bg-success {
				background-color: get($colors, green);
			}
		}
	}
}
