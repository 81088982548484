@import '../../../../../../../sass/utilites/variables';
@import '../../../../../../../sass/utilites/mixins';

.item {
	position: relative;
	padding-bottom: rem(25px);
	@include media-breakpoint-up(lt) {
		width: rem(192px);
	}
	@include media-breakpoint-down(lt) {
		width: 100%;
	}
	small {
		@include media-breakpoint-down(sm) {
			@include font-size($font-size-extra-small)
		}
	}
	&Wrap {
		background-color: $gray-400;
		border-radius: $border-radius-xl;
		transition-property: background-color;
		:global {
			.lazyImage {
				border-radius: $border-radius-xl;
			}
		}
	}
	:global {
		.text-m {
			@include media-breakpoint-down(xl) {
				@include font-size($font-size-extra-small-up);
			}
		}
	}
}

:global {
	:local(.item) {
		&.is-marked {
			.ratio {
				box-shadow: 0 0 0 $border-width get($colors, danger);
				border-radius: $border-radius-xl;
			}
		}
	}
}

.label {
	&Main, &Secondary {
		cursor: pointer;
		transition-duration: #{$transition-duration}s;
	}
	&Main {
		.itemIcon {
			@include center('both');
			width: rem(72px);
			height: rem(72px);
			background-color: rgba(get($colors, primary), .1);
			border-radius: 50%;
			svg {
				@include center('both');
			}
		}
	}
	&Secondary {
		@include media-breakpoint-up(sm) {
			color: get($colors, secondary);
		}
		@include media-breakpoint-down(sm) {
			color: get($colors, primary);
			border: $border-width solid get($colors, primary);
			padding: rem(6px) rem(8px);
			border-radius: $border-radius-pill;
			width: 100%;
		}
		.itemIcon {
			@include media-breakpoint-up(sm) {
				width: rem(30px);
				height: rem(30px);
				position: relative;
				border: $border-width solid get($colors, dark);
				border-radius: 50%;
				svg {
					@include center('both');
				}
			}

		}
		&Title {
			font-weight: 500;
		}
	}
}

.error {
	position: absolute;
	bottom: 0;
	width: 100%;
	left: 0;
}

.documentPlaceholder {
	display: block;
	max-width: rem(60px);
	height: 100%;
	margin: 0 auto;
}

.controls {
	 position: absolute;
	 right: rem(12px);
	 top: rem(12px);
	 z-index: get($index, sm);
}

.status {
	cursor: pointer;
	position: relative;
	&Icon {
		svg {
			width: rem(22px);
			height: rem(22px);
		}
	}
	&Content {
		transition-property: opacity, visibility, transform;
		transition-duration: #{$transition-duration}s;
		position: absolute;


		white-space: nowrap;
		border: $border-width solid $border-color;
		padding: rem(8px) rem(15px);
		background-color: get($colors, white);
		border-radius: $border-radius-sm;
		opacity: 0;
		visibility: hidden;
		transform: translateY(rem(10px));
		@include media-breakpoint-up(hd) {
			top: 50%;
			left: calc(100% + #{rem(5px)});
		}
		@include media-breakpoint-between(xl, hd) {
			right: 0;
			top: calc(100% + #{rem(5px)});
		}
	}
	@include media-breakpoint-up(xl) {
		&:hover {
			.statusContent {
				opacity: 1;
				visibility: visible;
				transform: translateY(0);
			}
		}
	}
}

:global {
	:local(.status) {
		&.is-not_approved {
			svg {
				color: get($colors, danger);
			}
		}
	}
}

.delete {
	@include transition(color);
	color: get($colors, dark);
	&:hover {
		color: get($colors, danger);
	}
}
