@import '../../../../../sass/utilites/variables';

.proposals {
	&Item {

		& + .proposalsItem {
			@include media-breakpoint-up(xl) {
				margin-top: $grid-gutter-width;
			}
			@include media-breakpoint-between(sm, xl) {
				margin-top: $grid-gutter-width / 2;
			}
			@include media-breakpoint-down(sm) {
				margin-top: $grid-gutter-width / 3;
			}
		}
	}
	:global {
		.ratio {
			img {
				object-fit: contain;
				object-position: left center;
			}
		}
	}
}
