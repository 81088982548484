@import '../../../../sass/utilites/variables';
@import '../../../../sass/utilites/mixins';

.item {
	border-radius: $border-radius-xl;
	text-align: center;
	display: inline-flex;
	align-items: center;
	svg {
		width: rem(20px);
		height: rem(20px);
	}
}

.content {
	margin-bottom: rem(-3px);
}

.sm {
	@include font-size($font-size-m);
	padding: rem(7px) rem(17px);
}

.md {
	@include media-breakpoint-up(xl) {
		@include font-size($body-font-size);
	}
	@include media-breakpoint-down(xl) {
		@include font-size($font-size-sm);
	}
	padding: rem(8px) rem(15px);
}

.default {
	color: get($colors, dark);
	background-color: $gray-200;
}

.success {
	background-color: #C0FFD2;
}

.info {
	background-color: #D4EEFF;
}
