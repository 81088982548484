@import '../../../../../sass/utilites/variables';
@import '../../../../../sass/utilites/mixins';

.root {
	position: relative;
}

$tooltip-width: rem(250px);
$tooltip-triangle-size: rem(10px);

.mobileTooltip {
	@include transition;
	position: absolute;
	top: calc(100% + #{$tooltip-triangle-size * 1.5});
	right: - $tooltip-width / 4;
	z-index: get($index, sm);
	background-color: get($colors, white);
	padding: $grid-gutter-width / 2;
	border-radius: $border-radius-pill;
	width: $tooltip-width;
	opacity: 0;
	visibility: hidden;
	transform: translateY(rem(10px));
	&:before {
		@include triangle('up', $tooltip-triangle-size, get($colors, white));
		top: 0;
		right: calc(#{$tooltip-width / 4} + #{rem(2px)});
		transform: translateY(- #{$tooltip-triangle-size});
	}
	&Message {
		line-height: $line-height-sm;
	}
	:global {
		.button {
			@include font-size($font-size-extra-small-up);
			color: get($colors, white);
		}
	}
}

:global {
	:local(.mobileTooltip) {
		&.is-visible {
			opacity: 1;
			visibility: visible;
			transform: translateY(0);
		}
	}
}
