@import '../../../../sass/utilites/variables';

$avatar-size-large:           rem(80px);
$avatar-size-small:           rem(50px);

$avatar-edit-size-large:      rem(24px);
$avatar-edit-size-small:      rem(20px);

$avatar-edit-border-size-large:     rem(3px);
$avatar-edit-border-size-small:     2px;

.form {
	$edit-button-offset: rem(5px);

	position: relative;
	background-color: get($colors, white);
	border-radius: 50%;
	@include media-breakpoint-up(lg) {
		width: $avatar-size-large;
		height: $avatar-size-large;
		.picture {
			width: $avatar-size-large;
			height: $avatar-size-large;
		}
	}
	@include media-breakpoint-down(lg) {
		width: $avatar-size-small;
		height: $avatar-size-small;
		.picture {
			width: $avatar-size-small;
			height: $avatar-size-small;
		}
	}
	.picture, .edit {
		border-radius: 50%;
	}
	.picture {
		overflow: hidden;
		cursor: pointer;
	}
	.edit {
		background-color: $body-bg;
		position: absolute;
		right: -$edit-button-offset;
		bottom: $edit-button-offset;
		color: get($colors, white);
		pointer-events: none;
		@include media-breakpoint-up(xl) {
			width: $avatar-edit-size-large;
			height: $avatar-edit-size-large;
		}
		@include media-breakpoint-down(xl) {
			width: $avatar-edit-size-small;
			height: $avatar-edit-size-small;
		}
		&:after {
			@include pseudo;

			background-color: get($colors, primary);
			border-radius: inherit;

			@include media-breakpoint-up(xl) {
				left: $avatar-edit-border-size-large;
				top: $avatar-edit-border-size-large;
				width: calc(100% - #{$avatar-edit-border-size-large * 2});
				height: calc(100% - #{$avatar-edit-border-size-large * 2});
			}
			@include media-breakpoint-down(xl) {
				left: $avatar-edit-border-size-small;
				top: $avatar-edit-border-size-small;
				width: calc(100% - #{$avatar-edit-border-size-small * 2});
				height: calc(100% - #{$avatar-edit-border-size-small * 2});
			}
		}
		&Icon {
			@include center('both');
			vertical-align: top;
			z-index: get($index, default);
			@include media-breakpoint-up(xl) {
				width: calc(100% - #{$avatar-edit-border-size-large * 3});
				height: calc(100% - #{$avatar-edit-border-size-large * 3});
			}
			@include media-breakpoint-down(xl) {
				width: calc(100% - #{$avatar-edit-border-size-small * 3});
				height: calc(100% - #{$avatar-edit-border-size-small * 3});
			}
		}
	}
}
