@import '../../../../../../../sass/utilites/variables';
@import '../../../../../../../sass/utilites/mixins';

.search {
	$icon-size-xl: rem(24px);
	$icon-x-offset-xl: rem(12px);

	$search-height-sm: rem(35px);

	position: relative;

	@include media-breakpoint-up(xl) {
		@include font-size($font-size-sm);
	}

	@include media-breakpoint-down(xl) {
		@include font-size($font-size-m);

		transition-duration: #{$transition-duration}s;
		transition-timing-function: ease-in-out;
		transition-property: width, border-radius;
		overflow: hidden;
		border-radius: 50%;
		width: $search-height-sm;
		height: $search-height-sm;
	}
	&Icon, &Reset {
		color: get($colors, dark);
		z-index: get($index, default);
	}

	&Icon {
		@include media-breakpoint-up(xl) {
			@include center('vertical');
			width: $icon-size-xl;
			height: $icon-size-xl;
			left: $icon-x-offset-xl;
		}
		@include media-breakpoint-down(xl) {
			@include center('both');
			width: 100%;
			height: 100%;
			padding: rem(8px);
		}
		svg {
			vertical-align: top;
		}
	}
	&Reset {
		transition-property: color, opacity;
		transition-duration: #{$transition-duration}s;
		@include center('vertical');
		text-decoration: underline;
		font-size: inherit;

		@include media-breakpoint-up(xl) {
			right: $icon-x-offset-xl * 2;
			&:hover {
				color: get($colors, black);
			}
		}
		@include media-breakpoint-down(xl) {
			@include font-size($font-size-extra-small);
			opacity: 0;
			visibility: hidden;
			right: rem(10px);
		}
	}
	&Expanded {
		@include media-breakpoint-down(xl) {
			width: rem(280px);
			border-radius: $input-border-radius;
			.search {
				&Icon {
					width: $search-height-sm;
					left: 0;
					transform: translate(0, -50%);
				}
				&Reset {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
	:global {
		.field {
			transition-property: box-shadow;

			border: 0 none;
			background-color: $gray-400;
			height: 100%;
			&:focus-visible, &:focus {
				box-shadow: 0 0 0 $border-width $gray-500;
			}
			@include media-breakpoint-up(xl) {
				padding-left: $icon-size-xl + $icon-x-offset-xl * 2;
				padding-right: rem(120px) + $icon-x-offset-xl * 3;
				padding-bottom: rem(15px);
			}
			@include media-breakpoint-down(xl) {
				@include font-size($font-size-m);
				padding: rem(6px) rem(110px) rem(6px) $search-height-sm;
			}
		}
	}

}

.modalSearch {
	&Results {
		display: block;
		:global {
			.accordion-header .accordion-button {
				text-align: left;
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				svg {
					@include transition(transform);
				}
				&[aria-expanded="true"] {
					svg {
						transform: rotate(90deg);
					}
				}
			}
		}
	}
	&Item {
		background-color: $gray-400;
		padding: rem(10px) $grid-gutter-width / 2;
		border-radius: $border-radius-xl;
		color: get($colors, primary);
		& + .modalSearchItem {
			margin-top: rem(10px);
		}
	}
	&Question {
		@include font-size($font-size-m);
		padding-right: rem(10px);
	}
	&Icon {
		flex-shrink: 0;
	}
	&Loader {
		position: absolute;
		right: $grid-gutter-width / 2;
		top: $grid-gutter-width / 2;
	}
}
