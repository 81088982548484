@import '../../../../sass/utilites/variables';
@import 'node_modules/bootstrap/scss/vendor/rfs';

.root {
	font-weight: 500;
}
.large {
	@include font-size($h1-font-size)
}
.middle {
	@include font-size($h2-font-size)
}
.small {
	@include font-size($h3-font-size)
}
