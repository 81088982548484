@import '../../../../sass/utilites/variables';

.title {
	@include media-breakpoint-up(xl) {
		text-transform: uppercase;
	}
	@include media-breakpoint-down(xl) {
		@include font-size($font-size-md);
		font-weight: 500;
	}


}

.inner {
	@include media-breakpoint-up(xl) {
		padding: 0 rem(50px);
		height: $profile-header-height;
	}
	@include media-breakpoint-down(xl) {
		padding: $grid-gutter-width / 2 0;
		border-bottom: $border-width solid $gray-200;
		:global {
			.button, .btn, button {
				@include media-breakpoint-down(lg) {
					color: $gray-500;
				}
			}
		}
	}
}

.logo {
	@include media-breakpoint-down(lg) {
		fill: get($colors, black);
	}
}

.menuButton {
	color: get($colors, dark);
	@include media-breakpoint-down(lg) {
		color: $gray-500;
	}
}

