@import '../../../../sass/utilites/variables';
@import '../../../../sass/utilites/mixins';

$image-size: rem(60px);

.wrap {
	background-color: lighten($gray-200, 2%);
	border-radius: $border-radius-pill;
	overflow: hidden;
}

.placeholder {
	color: get($colors, dark);
	&Content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: rem(10px);
		svg {
			width: $image-size;
			height: $image-size;
			display: block;
			margin: 0 auto;
		}
	}
}

.empty {
	@include font-size($font-size-m);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
}

.player {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}

.play {
	@include transition;
}

:global {
	:local(.player) {
		&.is-loaded {
			&:before {
				@include pseudo-wrapper;
				background-color: rgba(get($colors, black), .425);
			}
		}
	}
	:local(.play) {
		&.is-loaded {
			transform: translate(-50%, -50%) scale(1);
		}
		&.is-played {
			opacity: 0;
		}
	}
}

.play {
	@include transition(transform);
	@include center('both');
	z-index: get($index, default);
	transform: translate(-50%, -50%) scale(0);
	&:hover {
		transform: translate(-50%, -50%) scale(1.05);
	}
}
