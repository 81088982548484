@import "../../../../../sass/utilites/variables";
@import "../../../../../sass/utilites/mixins";

.heading {
	@include font-size($font-size-md);
}

.link {
	color: get($colors, primary);
	text-decoration: underline;
	transition: all 0.2s ease-in-out;
}

.buttonCopy {
	border-radius: 10px;
	width: 100%;
	padding: rem(9px) rem(16px) rem(8px);

	@include media-breakpoint-up(md) {
		padding: rem(9px) rem(25px);
		width: 348px;
	}

	> span {
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-items: center;
		> span {
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}

	svg {
		fill: none;
		flex-shrink: 0;
	}

	&:hover {
		svg {
			transform: translateX(0) !important;
		}
	}
}

.copyWrap {
	display: flex;
	position: relative;
	gap: 16px;
	flex-direction: column;

	@include media-breakpoint-up(md) {
		flex-direction: row;
		gap: 24px;
		align-items: center;
	}
}

.textWrap {
	p {
		margin: 20px 0;
		line-height: 24px;
	}
}

.blockWithImg {
	@include media-breakpoint-up(xl) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 74px;
	}
}

.container,
.blockWrap {
	display: flex;
	flex-direction: column;
	gap: 50px;
}

.imgWrapper {
	display: none;

	@include media-breakpoint-up(xl) {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
	}
}

.blockItem {
	display: flex;
	flex-direction: column;
	gap: 20px;
	position: relative;
}

.blockItemShort {
	@include media-breakpoint-up(xl) {
		width: 80%;
	}
}
