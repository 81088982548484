@import "../../../sass/utilites/variables";
@import "../../../sass/utilites/mixins";

.root {
	display: flex;
	@include media-breakpoint-up(xl) {
		height: 100vh;
		overflow-x: hidden;
	}
	@include media-breakpoint-down(md) {
		min-height: 100vh;
	}
	@include media-breakpoint-up(xxl) {
		--#{$variable-prefix}gutter-x: #{rem(45px)};
	}
	@include media-breakpoint-between(sm, xxl) {
		--#{$variable-prefix}gutter-x: #{rem(30px)};
	}
	@include media-breakpoint-down(sm) {
		--#{$variable-prefix}gutter-x: #{rem(16px)};
	}
}

.content {
	flex: 1 0 auto;
	display: flex;
	justify-content: center;
	position: relative;
	@include media-breakpoint-up(xl) {
		align-items: center;
	}
	@include media-breakpoint-down(xl) {
		padding-top: 5vh;
		flex-direction: column;
	}

	a {
		transition: all 0.25s ease-in-out;
		color: get($colors, primary);
	}
}

.icon {
	max-width: 75vw;
	color: $gray-200;
	@include media-breakpoint-down(lg) {
		width: rem(130px);
		height: rem(30px);
		display: block;
		margin-left: auto;
		margin-right: auto;
		path {
			fill: get($colors, black);
		}
	}
	@include media-breakpoint-up(lg) {
		@include center("horizontal");
		top: 5vh;
		z-index: get($index, default);
	}
}

.illustration {
	width: 100%;
	@include media-breakpoint-up(xl) {
		max-width: 40vw;
		transform: translate(10%, 10%);
		position: absolute;
		right: 0;
		bottom: 0;
		z-index: get($index, md);
	}
	@include media-breakpoint-down(xl) {
		overflow: hidden;
		text-align: right;
		flex: 0 0 auto;
		margin-top: -rem(100px);
		height: rem(500px);
		:global {
			.lazyImage {
				width: auto;
			}
			.ratio {
				&:before {
					display: none;
				}
			}
		}
	}
	@include media-breakpoint-between(sm, xl) {
		:global {
			.lazyImage {
				transform: translateX(10%);
			}
		}
	}
	@include media-breakpoint-down(sm) {
		:global {
			.lazyImage {
				transform: translateX(24%);
			}
		}
	}

	pointer-events: none;
	:global {
		.lazyImage {
			object-fit: contain;
		}
	}
}

.toast {
	$btn-size: 1.8em;
	@include font-size($font-size-m);
	position: absolute;
	left: $spacer * 2;
	top: $spacer * 2;
	z-index: get($index, md);
	&ErrorIcon {
		width: $btn-size;
		height: $btn-size;
		flex: 0 0 auto;
		fill: get($colors, danger);
		margin-right: $btn-size / 2;
	}
	&Content {
		font-weight: 500;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		padding-top: rem(3px);
	}
}
