@import '../../../../sass/utilites/variables';
@import '../../../../sass/utilites/mixins';



.root {
	flex: 1 0 auto;
	@include media-breakpoint-down(xl) {
		padding-left: $grid-gutter-width / 2;
		padding-right: $grid-gutter-width / 2;
	}
}

.view {
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	@include media-breakpoint-up(xl) {
		border-top-left-radius: $border-radius-pill * 1.5;
		padding: $content-padding-xl $content-padding-xl $content-padding-xl * 2;

		box-shadow: 0 rem(4px) rem(53px) 0 $shadow-color;
		background-color: get($colors, white);
	}
}

.area {
	position: relative;
	@include media-breakpoint-up(xl) {
		height: calc(100% - #{$profile-header-height});
	}
	:global {
		.tabs__controls {
			@include media-breakpoint-up(xl) {
				z-index: get($index, default);
				left: rem(35px);
				top: 0;
				transform: translateY(-100%);
				position: absolute;
			}
		}
	}
}

.btnToUp {

	width: rem(50px);
	height: rem(50px);
	background-color: get($colors, white);
	border-radius: $border-radius-xl;
	&Wrap {
		@include media-breakpoint-up(xl) {
			bottom: $grid-gutter-width;
			right: $content-padding-xl;
			position: absolute;
		}
		@include media-breakpoint-down(xl) {
			position: fixed;
			right: $grid-gutter-width;
			bottom: $grid-gutter-width;
			z-index: get($index, default);
		}
	}
	&:hover {
		:global {
			.button {
				border-color: get($colors, primary);
			}
			svg {
				transform: translateY(-#{rem(2px)});
			}
		}
	}
	:global {
		svg {
			@include transition(transform);
		}
		.button {
			border: 0 none;
			width: inherit;
			height: inherit;
			color: get($colors, primary);
			box-shadow: get($shadows, regular);
		}
	}
}

