@import '../../../../../sass/utilites/variables';

.holder {
	position: relative;
	@include media-breakpoint-between(sm, xl) {
		border-radius: $border-radius-pill;
		padding: $grid-gutter-width / 2 * 2.5 $grid-gutter-width / 2 $grid-gutter-width / 2 * 2;

		box-shadow: 0 rem(4px) rem(53px) 0 $shadow-color;
		background-color: get($colors, white);
	}
	@include media-breakpoint-down(xl) {
		padding-bottom: rem(50px);
	}
}

