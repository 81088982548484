@import '../../../../sass/utilites/variables';
@import '../../../../sass/utilites/mixins';

.wrapper {
	.loader {
		left: 50%;
		transform: translateX(-50%);
		width: rem(50px);
		max-width: 50%;
	}
	.errorIcon {
		transform: translate(-50%, -50%);
		width: auto;
		height: auto;
		left: 50%;
		top: 50%;
		fill: get($colors, danger);
	}
}

.image {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}



