@import '../../../../sass/utilites/variables';

.container {
	@include font-size($font-size-extra-small);
	background-color: get($colors, white);
	border: $border-width solid $gray-200;
	padding: rem(10px) rem(15px);
	border-radius: $border-radius-lg;
	z-index: get($index, lg);
	max-width: rem(320px);
	min-width: rem(240px);
}
