@import "../../../../sass/utilites/variables";
@import "../../../../sass/utilites/mixins";

$aside-width-xxl: rem(370px);
$aside-width-xl: rem(335px);

.root {
	padding-top: rem(35px);
	padding-bottom: rem(35px);
	cursor: default;
	@include media-breakpoint-up(xxl) {
		flex: 0 0 $aside-width-xxl;
		max-width: $aside-width-xxl;
		padding-left: rem(25px);
		padding-right: rem(25px);
		overflow: auto;
	}
	@include media-breakpoint-between(xl, xxl) {
		flex: 0 0 $aside-width-xl;
		max-width: $aside-width-xl;
		padding-left: rem(20px);
		padding-right: rem(20px);
	}
	@include media-breakpoint-down(xl) {
		//display: none !important;
	}
}

.link {
	@include transition(color);
	padding-left: rem(20px);
	display: inline-flex;
	align-items: center;
	&Icon,
	&Name {
		transition-duration: #{$transition-duration}s;
	}
	&Icon {
		margin-left: rem(5px);
		transition-property: color, transform;
	}
	&Name {
		margin-top: -#{rem(2px)};
		transition-property: color;
	}
	&:hover {
		color: get($colors, primary);
		.linkIcon {
			transform: translateX(rem(4px));
		}
	}
}
