@import '../../../../../../sass/utilites/variables';
@import '../../../../../../sass/utilites/mixins';

.main {
	$slider-height:           15px;
	$slider-border-radius:    4px;
	$rail-height:             5px;

	$slider-height-offset:    #{($slider-height - $rail-height) / 2};
	position: relative;

	:global {
		.rc-slider {
			height: $slider-height;
			border-radius: $slider-border-radius;
			padding: $slider-height-offset 0;
			&-handle {
				width: $slider-height;
				height: $slider-height;
				margin-top: - $slider-height-offset;
			}
			&-rail, &-track {
				border-radius: $slider-border-radius;
				height: $rail-height;
			}
			&-tooltip {
				&-inner {
					border-radius: $slider-border-radius;
				}
			}
			&-dot {
				width: $rail-height;
				height: $rail-height;
				margin-left: -$rail-height / 2;
				bottom: initial;
				top: 0;
			}
			&-mark {
				top: 0;
				&-text {
					color: get($colors, dark);
					margin-top: calc(-#{$slider-height} - #{$font-size-extra-small-up / 2});
					white-space: nowrap;
					&:first-of-type {
						margin-left: -$slider-height / 2.35;
						transform: none!important;
					}
					&:last-of-type {
						transform: translateX(calc(-100% + #{$slider-height / 2.25})) !important;
					}
				}
			}
		}
	}
}

.section {
	@include font-size($font-size-extra-small-up);
	& + .section {
		margin-top: rem(40px);
	}
}


.field {
	width: rem(100px);
	border: $border-width solid $input-border-color;
	border-radius: $input-border-radius;
	input {
		width: 100%;
		border: 0 none;
		border-radius: inherit;
		padding: rem(6px) rem(12px);
	}
}

.reset {
	@include transition;
	font-weight: 500;
	display: inline-block;
	padding-top: rem(10px);
}
