@import 'mixins';
@import 'rem';
@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/functions';

/* Colors variables start */
$colors: (
	primary:                  #20AAFF,
	secondary:                #0087DA,
	danger:                   #EB5757,
	success:                  #198754,
	info:                     #0dcaf0,
	warning:                  #ffc107,

	dark:                     #96A6B8,
	light:                    #F4F6FB,

	black: 		                #3A3A3A,
	white: 		                #ffffff,

	yellow:                    #F2994A,
	green:                     #3BDA97,
	pear:                      #F2C94C,
	turquoise:                 #24DDC7,
	jade:                      #00C07B
);

$gray-200:                  #E7EBF5;
$gray-300:                  #F9FAFD;
$gray-400:                  #F6F8FC;
$gray-500:                  #CBD3DC;

$shadow-color:              #E8ECF4;

/* Colors variables end */


/* Custom variables start*/
$index: (
	negative: -1,
	default: 1,
	xs: 2,
	sm: 3,
	md: 5,
	lg: 7,
	xl: 9
);

$transition-duration:                         .25;

$pics: (
	close: 'data:image/svg+xml;base64,PHN2ZyAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgICB3aWR0aD0iMTQuMDIwMjAxIiAgIGhlaWdodD0iMTQuMDIwMjAxIiAgIHZpZXdCb3g9IjAgMCAxNC4wMjAyMDEgMTQuMDIwMjAxIj4gIDxwYXRoIGQ9Im0gMTIuOTkwMjM0LDAgYSAxLjAwMDEsMS4wMDAxIDAgMCAwIC0wLjY4NzUsMC4zMDI3MzQzOCBMIDAuMzAyNzM0MzgsMTIuMzAyNzM0IEEgMS4wMDAxLDEuMDAwMSAwIDEgMCAxLjcxNjc5NjksMTMuNzE2Nzk3IEwgMTMuNzE2Nzk3LDEuNzE2Nzk2OSBBIDEuMDAwMSwxLjAwMDEgMCAwIDAgMTIuOTkwMjM0LDAgWiIvPiAgPHBhdGggZD0iTSAxLDAgQSAxLjAwMDEsMS4wMDAxIDAgMCAwIDAuMzAyNzM0MzgsMS43MTY3OTY5IEwgMTIuMzAyNzM0LDEzLjcxNjc5NyBhIDEuMDAwMSwxLjAwMDEgMCAxIDAgMS40MTQwNjMsLTEuNDE0MDYzIEwgMS43MTY3OTY5LDAuMzAyNzM0MzggQSAxLjAwMDEsMS4wMDAxIDAgMCAwIDEsMCBaIi8+PC9zdmc+',
	dropdown-arrow-black: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNSAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMy43NSA1LjYyNUw3LjUgOS4zNzVMMTEuMjUgNS42MjUiIHN0cm9rZT0iIzNBM0EzQSIgc3Ryb2tlLXdpZHRoPSIxLjI1IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4=',
	gear-primary: 'data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMzIgMzIiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgMzIgMzIiIHdpZHRoPSI1MTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTI4LjY3IDE4LjQzLTIuMDEtMS43OGMwLS4xMS0uMDItMS40NS0uMDMtMS41NSAwIDAgMS45OC0xLjg0IDEuOTktMS44NS4yNC0uMzIuNC0uNjguMjUtMS4wNy0uMTMtLjQ2LS4yOS0uOTMtLjUyLTEuNDYtLjIxLS40OS0uNDYtLjk4LS43NC0xLjQ2LS4xOC0uMzEtLjU2LS41Mi0uOTMtLjUtLjkuMDYtMS44LjEyLTIuNy4xOC0uMDYtLjA4LTEuMDItMS4wMi0xLjEtMS4wOS4wMy0uOS4wNi0xLjc5LjA5LTIuNjkuMDEtLjM2LS4yLS43NC0uNTEtLjkxLS45Ny0uNTUtMS45OS0uOTUtMy4wNC0xLjIxLS4zNy0uMDktLjc1LjAzLS45OS4zMWwtMS43OCAyLjAxYy0uNTItLjAzLTEuMDQtLjAyLTEuNTUuMDNsLTEuODQtMS45OGMtLjI1LS4yNy0uNjMtLjM4LS45OS0uMjktMS4wNS4yOS0yLjA5LjczLTMuMDMgMS4yOS0uMzIuMi0uNS41NS0uNDguOTJsLjE4IDIuN2MtLjQuMzUtLjc2LjcyLTEuMDkgMS4xMWwtMi43LS4wOWMtLjM2LS4wMi0uNzIuMTgtLjkuNTEtLjU1Ljk3LS45NSAxLjk5LTEuMjEgMy4wNC0uMDkuMzYuMDMuNzQuMzEuOTlsMi4wMSAxLjc4Yy0uMDMuNTItLjAyIDEuMDMuMDMgMS41NWwtMS45OCAxLjg0Yy0uMjcuMjUtLjM4LjYzLS4yOS45OS4yOCAxLjA1Ljc0IDIuMDcgMS4yOCAzLjAxLjE5LjMzLjU0LjUyLjkzLjVsMi43LS4xOGMuMzUuNC43Mi43NiAxLjExIDEuMDlsLS4wOSAyLjdjLS4wMS4zNy4xOC43Mi41MS45LjkzLjUzIDEuOTEuOTEgMi45NSAxLjE4LjM3LjEuODEuMDMgMS4wOC0uMjhsMS43OC0yLjAxYy41MS4wMiAxLjA0LjAyIDEuNTUtLjAzIDAgMCAxLjg0IDEuOTggMS44NiAyIC4yOC4yOS42OC4zNCAxLjA4LjIzIDEuMDEtLjI4IDEuOTktLjcyIDIuOS0xLjI1LjMxLS4xNy41Mi0uNTcuNS0uOTMtLjA2LS45LS4xMi0xLjgtLjE4LTIuNy4yMi0uMTkuNDItLjM5LjYyLS42LjA2LS4wNy4zNy0uNS40Ny0uNS45LjAzIDEuNzkuMDYgMi42OS4wOS4zNS4wMi43My0uMi45MS0uNTEuNTMtLjk0LjkyLTEuOTMgMS4xOC0yLjk1LjEtLjM4LjAzLS44MS0uMjgtMS4wOHptLTEyLjY2IDIuNThjLTIuNzYgMC01LTIuMjQtNS01czIuMjQtNSA1LTUgNSAyLjI0IDUgNS0yLjI0IDUtNSA1eiIgZmlsbD0iIzIwQUFGRiIvPjwvc3ZnPg=='
);

$shadows: (
	small: 0 rem(4px) rem(10px) $shadow-color,
	regular:  0 rem(7px) rem(26px) $shadow-color
);

$profile-header-height:                rem(125px);


$toastify-padding:                        10px;
$selectClassName:                         'custom-select';
/* Custom variables end*/


/* Bootstrap variables start*/
$theme-colors: (
	"primary":    get($colors, primary),
	"secondary":  get($colors, secondary),
	"success":    get($colors, success),
	"info":       get($colors, info),
	"warning":    get($colors, warning),
	"danger":     get($colors, danger),
	"light":      get($colors, light),
	"dark":       get($colors, dark)
);

$theme-colors-rgb: map-loop($theme-colors, to-rgb, '$value');

$variable-prefix:                         'page-';

$enable-grid-classes:                     true;
$enable-cssgrid:                          true;
$enable-negative-margins:                 true;
$enable-important-utilities:              false;
$enable-smooth-scroll:                    true;
$enable-button-pointers:                  true;

$spacer: rem(12px);
$spacers: (
	0: 0,
	1: $spacer * .25,
	2: $spacer * .5,
	3: $spacer,
	4: $spacer * 1.5,
	5: $spacer * 3,
	6: $spacer * 4.5
);

$font-family-sans-serif:                  'Roboto', sans-serif;
$font-family-monospace:                   SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

$font-family-base:                        $font-family-sans-serif;
$font-family-code:                        $font-family-monospace;

$line-height-base:                        1.35;
$line-height-sm:                          1.15;
$line-height-md:                          1.5;
$line-height-lg:                          2;

$font-size-root:                          null;
$font-size-extra-small:                   rem(12px);
$font-size-extra-small-up:                rem(13px);
$font-size-m:                             rem(14px);
$font-size-sm:                            rem(15px);
$font-size-base:                          rem(16px);
$font-size-md:                            rem(18px);
$font-size-lg:                            $font-size-base * 1.25;
$font-size-xl:                            rem(24px);

$small-font-size:                         .8em;
$sub-sup-font-size:                       .75em;

$code-font-size:                          $small-font-size;
$pre-color:                               null;
$code-color:                              null;

$table-cell-padding-y:                    $spacer * .75;
$table-caption-color:                     null;
$table-th-font-weight:                    null;

$body-bg:                                 get($colors, light);
$body-color:                              get($colors, black);
$body-font-family:                        $font-family-sans-serif;
$body-text-align:                         null;
$body-font-size:                          $font-size-base;

$link-color:                              inherit;
$link-decoration:                         none;
$link-hover-color:                        get($colors, black);
$link-hover-decoration:                   null;

$headings-margin-bottom:                  $spacer * .75;
$headings-font-family:                    null;
$headings-font-style:                     null;
$headings-font-weight:                    null;
$headings-line-height:                    $line-height-sm;
$headings-color:                          null;

$h1-font-size:                            rem(24px);
$h2-font-size:                            rem(16px);
$h3-font-size:                            rem(14px);
$h4-font-size:                            $font-size-base * 1.5;
$h5-font-size:                            $font-size-base * 1.25;
$h6-font-size:                            $font-size-base;

$paragraph-margin-bottom:                 $spacer * .75;

$offcanvas-horizontal-width:              rem(440px);
$offcanvas-vertical-height:               rem(350px);
$offcanvas-padding-y:                     $spacer * 2;
$offcanvas-padding-x:                     $spacer * 2;
$offcanvas-transition-duration:           #{$transition-duration}s;
$offcanvas-border-width:                  0;

$grid-breakpoints: (
	xs: 0,
	m: 375px,
	sm: 576px,
	md: 740px,
	lg: 992px,
	xl: 1200px,
	xxl: 1400px,
	lt:  1600px,
	hd:  1820px
);
$container-max-widths: (
	m: 360px,
	sm: 540px,
	md: 710px,
	lg: 960px,
	xl: 1140px,
	xxl: 1320px,
	lt:  1520px,
	hd:  1720px
);

$grid-columns:                            12;
$grid-gutter-width:                       rem(30px);
$grid-row-columns:                        6;

$gutters:                                 $spacers;

$container-padding-x:                     $grid-gutter-width * .5;

$border-width:                            1px;
$border-widths: (
	default:                                1px,
	small:                                  2px,
	middle:                                 3px,
	large:                                  4px,
	extra:                                  5px
);
$border-color:                            #E3E8F3;

$border-radius:                           2px;
$border-radius-sm:                        4px;
$border-radius-lg:                        6px;
$border-radius-xl:                        10px;
$border-radius-xxl:                       18px;
$border-radius-pill:                      rem(26px);

$aspect-ratios: (
	'1x1':                                  100%,
	'4x3':                                  calc(3 / 4 * 100%),
	'4x20':                                 calc(9 / 44 * 100%),
	'16x9':                                 calc(9 / 16 * 100%),
	'21x9':                                 calc(9 / 21 * 100%)
);

$font-weight-light:                       300;
$font-weight-normal:                      400;
$font-weight-medium:                      500;
$font-weight-bold:                        700;
$font-weight-base:                        $font-weight-normal;

$spinner-width:                           1.75em;
$spinner-height:                          $spinner-width;

$hr-margin-y:                             0;
$hr-color:                                get($colors, black);
$hr-height:                               $border-width;

$legend-margin-bottom:                    $spacer * .5;
$legend-font-size:                        $font-size-base * 1.25;
$legend-font-weight:                      null;

$mark-padding:                            .2em;
$mark-bg:                                 #fcf8e3;

$kbd-padding-y:                           .2rem;
$kbd-padding-x:                           .4rem;
$kbd-font-size:                           $code-font-size;
$kbd-color:                               get($colors, white);
$kbd-bg:                                  darken(get($colors, white), 10%);
$nested-kbd-font-weight:                  $font-weight-bold;

$input-padding-y:                         rem(16px);
$input-padding-x:                         rem(16px);
$input-focus-border-color:                get($colors, primary);
$input-border-radius:                     rem(10px);
$input-border-color:                      $border-color;

$toast-padding-x:                         rem(14px);
$toast-max-width:                         rem(350px);
$toast-border-color:                      transparent;
$toast-header-color:                      get($colors, dark);

$btn-close-focus-shadow:                  none;

$modal-header-border-color:               transparent;
$modal-header-padding-y:                  rem(25px);
$modal-header-padding-x:                  rem(25px);
$modal-backdrop-opacity:                  .7;
$modal-content-border-radius:             rem(27px);
$btn-close-opacity:                       .25;
$btn-close-hover-opacity:                 .75;
$btn-close-width:                         rem(14px);
$modal-sm:                                rem(428px);
$modal-md:                                rem(614px);
$modal-inner-padding:                     rem(90px);

$spinner-width-sm:                        1rem;
$spinner-height-sm:                       1rem;

$progress-bg:                             $gray-200;
$progress-bar-bg:                         get($colors, primary);
$progress-height:                         rem(8px);
$progress-border-radius:                  $border-radius-xl;
/* Bootstrap variables end*/


/* Responsive font-size start*/
$rfs-base-font-size: 											$font-size-base;
$rfs-font-size-unit: 											rem;
$rfs-breakpoint: 													get($grid-breakpoints, xl);
$rfs-breakpoint-unit: 										px;
$rfs-two-dimensional: 										false;
$rfs-factor: 															20;
$rfs-class: 															false;
$rfs-rem-value: 													18;
$rfs-safari-iframe-resize-bug-fix: 				false;
$enable-responsive-font-sizes: 						true;
/* Responsive font-size end*/


/*Custom start*/
$error-spacer: rem(18px);

$range-height:           24px;
$range-border-radius:    8px;
$rail-height:            8px;

$simplebar-size-sm: 6px;
$simplebar-size-lg: 8px;

$content-padding-xl: rem(50px);

/*Custom end*/
