@import '../../../../sass/utilites/variables';

.header {
	background-color: get($colors, light);
	border-bottom: $border-width solid $gray-200;

}

.dialog {
	:global {
		.offcanvas {
			&-header {
				padding-top: rem(17px);
				padding-bottom: rem(17px);
			}
			&-body {
				background-color: get($colors, light);
				padding-top: 0;
			}
		}
	}
}

.nav {
	background-color: get($colors, white);
	margin-left: -$offcanvas-padding-x;
	margin-right: -$offcanvas-padding-x;
}

.balance {
	padding-top: rem(14px);
	padding-bottom: rem(16px);
	&Amount {
		@include font-size($font-size-lg);
	}
	:global {
		.button {
			@include font-size($font-size-extra-small);
			min-width: initial;
		}
	}
}
