@import '../../../../../sass/utilites/variables';
@import '../../../../../sass/utilites/mixins';

.root .tableSection {
	@include media-breakpoint-up(sm) {
		margin-top: rem(30px);
	}
}

.header {
	:global {
		@include media-breakpoint-up(lt) {
			.field, .#{$selectClassName}__container {
				width: rem(210px);
			}
		}
	}
}

.months {
	@include media-breakpoint-down(md) {
		//overflow-x: auto;
		//width: calc(100% + #{$grid-gutter-width / 2});
		//margin-right: -$grid-gutter-width / 2;
	}
}

.month {
	@include font-size($font-size-m);
	transition-property: box-shadow, border-color, color;
	transition-duration: #{$transition-duration / 2}s, #{$transition-duration}s, #{$transition-duration}s;
	display: inline-block;
	border: $border-width solid get($colors, dark);
	border-radius: $input-border-radius;
	@include media-breakpoint-up(sm) {
		padding: rem(6px) rem(16px);
	}
	@include media-breakpoint-down(sm) {
		padding: rem(4px) rem(12px);
	}
	&:hover {
		color: get($colors, primary);
		border-color: get($colors, primary);
	}
}

.radio {
	cursor: pointer;
	display: inline-block;
	user-select: none;
	white-space: nowrap;
	input[type='radio'] {
		&:checked {
			& + .month {
				border-color: get($colors, primary);
				color: get($colors, primary);
				box-shadow: 0 0 0 $border-width get($colors, primary);
			}
		}
	}
}

.dash {
	color: $border-color;
	font-size: rem(17px);
}

.icon {
	padding: rem(13px) rem(15px);
	background-color: $gray-400;
	border-radius: $border-radius-xl;
}

.table {
	tr {
		th, td {
			&:first-of-type {
				width: 7.5%;
				padding-left: 0;
			}
			&:last-of-type {
				padding-right: 0;
			}
			&:nth-of-type(2) {
				width: 17.5%;
			}
			&:nth-of-type(3),
			&:nth-of-type(4) {
				width: 7.5%;
			}
			&:nth-of-type(5) {
				width: 22%;
				padding-right: 1%;
			}
			&:nth-of-type(6) {
				width: 17.5%;
			}
			&:nth-of-type(7),
			&:nth-of-type(8) {
				width: 9%;
			}
		}
	}
}

.accordionButton {
	width: 100%;
	position: relative;
	font-weight: 500;
	svg {
		@include transition(transform);
		fill: none;
		transform: rotate(0);
	}
	path {
		stroke: currentColor;
	}

}

.tableSection {
	@include media-breakpoint-down(sm) {
		overflow: hidden;
	}
}

/*.filtersSection {
	overflow: hidden;
}*/

:global {
	:local(.accordionButton) {
		&.collapsed {
			svg {
				transform: rotate(-180deg);
			}
		}
	}
}
