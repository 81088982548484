@import '../../../../../../../sass/utilites/variables';
@import '../../../../../../../sass/utilites/mixins';

$box-alpha:       .08;
$box-alpha-hover: .12;

.box {
	transition-property: background-color, box-shadow;
	transition-duration: #{$transition-duration}s;
	border-radius: $border-radius-xxl;
	box-shadow: 0 0 0 $border-width transparent;
	user-select: none;
	@include media-breakpoint-up(xl) {
		height: 100%;
	}
	@include media-breakpoint-down(xl) {
		padding: rem(17px) rem(20px);
	}
	&Accordion {
		&Header {

		}
		&Content {
			@include font-size($font-size-m);
			color: get($colors, black);
		}
		&Answer {
			color:  get($colors, dark);
			padding-top: rem(10px);
		}
		&Item {
			padding: rem(20px) 0;
			&:not(:last-child) {
				border-bottom: $border-width solid $border-color;
			}
			&:last-child {
				padding-bottom: 0;
			}
		}
	}
}

:global {
	:local(.boxAccordion) {
		.accordion-button {
			display: block;
			width: 100%;
			text-align: left;
			&[aria-expanded='true'] {
				svg {
					transform: rotateX(0);
				}
			}
		}
	}
}

.boxIcon {
	color: inherit;
}

.boxLabel {
	@include media-breakpoint-up(xl) {
		text-align: center;
		padding: rem(20px);
		cursor: pointer;
	}
}

.warning {
	color: get($colors, yellow);
	background-color: rgba(get($colors, yellow), $box-alpha);
	&:hover {
		background-color: rgba(get($colors, yellow), $box-alpha-hover);
	}
}

.success {
	color: get($colors, green);
	background-color: rgba(get($colors, green), $box-alpha);
	&:hover {
		background-color: rgba(get($colors, green), $box-alpha-hover);
	}
}

.danger {
	color: get($colors, danger);
	background-color: rgba(get($colors, danger), $box-alpha);
	&:hover {
		background-color: rgba(get($colors, danger), $box-alpha-hover);
	}
}

.info {
	color: get($colors, primary);
	background-color: rgba(get($colors, primary), $box-alpha);
	&:hover {
		background-color: rgba(get($colors, primary), $box-alpha-hover);
	}
}

:global {
	@include media-breakpoint-up(xl) {
		:local(.box) {
			&.is-checked {
				label {
					cursor: default;
				}
			}
		}
		:local(.warning) {
			&.is-checked {
				box-shadow: 0 0 0 $border-width get($colors, yellow);
			}
		}
		:local(.success) {
			&.is-checked {
				box-shadow: 0 0 0 $border-width get($colors, green);
			}
		}
		:local(.danger) {
			&.is-checked {
				box-shadow: 0 0 0 $border-width get($colors, danger);
			}
		}
		:local(.info) {
			&.is-checked {
				box-shadow: 0 0 0 $border-width get($colors, primary);
			}
		}
	}
}

.title {
	color: get($colors, black);
	font-weight: 500;
}

.arrowUp {
	color: get($colors, black);
	width: rem(22px);
	height: rem(22px);
	padding: rem(5px);
	&Circle {
		border-radius: 50%;
		border: $border-width solid rgba(get($colors, black), .3);
	}
	svg {
		@include transition(transform);
		vertical-align: top;
		transform: rotateX(180deg);
	}
}
