@import '../../../../sass/utilites/variables';

.item {
	position: relative;
	@include media-breakpoint-up(xl) {
		min-height: rem(135px);
		& + .item {
			margin-top: rem(65px);
		}
	}
	@include media-breakpoint-between(sm, xl) {
		& + .item {
			margin-top: $grid-gutter-width;
		}
	}
	@include media-breakpoint-down(sm) {

		background-color: get($colors, white);
		border-radius: $border-radius-pill;
		box-shadow: get($shadows, regular);
		padding: rem(20px) $grid-gutter-width / 2;
		& + .item {
			margin-top: rem(16px);
		}
	}
}

:global {
	:local(.item) {
		&.is-table-inside {
			@include media-breakpoint-down(sm) {
				width: calc(100% + #{$grid-gutter-width / 2});
				margin-right: - $grid-gutter-width / 2;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
		&.is-limit-min-height {
			min-height: rem(120px);
		}
	}
}

.heading {
	@include media-breakpoint-down(lg) {
		@include font-size($font-size-md)
	}
}

