@import '../../../../../../../sass/utilites/variables';

.header {}

.main {
	:global {
		.rc {
			&-slider {
				$range-height-offset:    #{($range-height / 2 - $rail-height / 2) / 2};

				height: $range-height / 2;
				padding: $range-height-offset 0;
				&-rail, &-track {
					height: $rail-height / 2;
				}
				&-handle {
					width: $range-height / 1.2;
					height: $range-height / 1.2;
					margin-top: - $range-height-offset / 1.2;
					border: 0 none;
				}
			}

		}
	}
}

.title {
	font-weight: 500;
}

.rangeOutput {
	@include font-size($font-size-sm);
	strong, b {
		@include font-size($font-size-md);
		font-weight: 500;
	}
}
