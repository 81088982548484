@import '../../../../sass/utilites/variables';

.body {
	background-color: $body-bg;
}

.loader {
	position: absolute;
	right: 0;
	top: 0;
}

.list {
	margin-left: - $grid-gutter-width / 2;
	margin-right: - $grid-gutter-width / 2;
	width: calc(100% + #{$grid-gutter-width});
	border-style: solid;
	border-width: $border-width 0 $border-width 0;
	border-color: $border-color;
	color: get($colors, dark);
	&Item {
		background-color: get($colors, white);

		padding: $grid-gutter-width / 2;
		padding-right: $grid-gutter-width / 9;
		&:not(:last-of-type) {
			border-bottom: $border-width solid $border-color;
			padding-bottom: $grid-gutter-width / 2;
			&:after {
				display: none;
			}
		}
	}
}

