@import '../../../../../sass/utilites/variables';
@import '../../../../../sass/utilites/mixins';

.main {
	height: 100%;
	@include media-breakpoint-up(lg) {
		padding: rem(22px) rem(20px);
	}
	@include media-breakpoint-between(md, lg) {
		padding: rem(15px);
	}
	@include media-breakpoint-down(md) {
		padding: $grid-gutter-width / 2;
	}
	border-radius: rem(18px);
}

.title {
	opacity: .5;
	line-height: get($line-height-sm);
	@include media-breakpoint-down(lg) {
		@include font-size(17px)
	}
}

.amount {
	@include media-breakpoint-up(lg) {
		@include font-size($h1-font-size);
	}
	@include media-breakpoint-down(lg) {
		@include font-size($h2-font-size);
	}
	font-weight: bold;
}
