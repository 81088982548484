@import '../../../../sass/utilites/variables';
@import '../../../../sass/utilites/mixins';

.root {
	border-radius: rem(17px);
	padding: rem(30px) rem(50px) rem(30px) rem(30px);
	position: relative;
	overflow: hidden;
	min-height: rem(135px);
	@include media-breakpoint-up(sm) {
		background-color: $gray-300;
	}
	@include media-breakpoint-down(sm) {
		background-color: get($colors, white);
		box-shadow: get($shadows, regular);
	}
	:global {
		hr {
			background-color: $gray-200;
			@include media-breakpoint-up(md) {
				margin: rem(20px) 0;
			}
			@include media-breakpoint-down(md) {
				margin: rem(15px) 0;
			}
		}
	}
}

.subtitle {
	@include font-size($font-size-sm);
	font-weight: 500;
}

.title {
	@include font-size($font-size-md);
	font-weight: 500;
	line-height: $line-height-md;
}

.message {
	padding-top: rem(20px);
	max-width: 80%;
	position: relative;
	z-index: get($index, default);
}

.decoration {
	position: absolute;
	right: 0;
	transform: translate(47.5%, 50%);
	bottom: 0;
}

.star {
	width: rem(100px);
	height: rem(100px);
	z-index: get($index, default);

}

.star,
.verificationNotVerifiedIcon,
.verificationOnCheckIcon,
.verificationChangeOnCheckStarIcon,
.verificationChangeOnCheckWatchIcon,
.qualificationOnCheckIcon,
.qualificationQualifiedIcon,
.lawTypeTrianglesIcon,
.lawTypeLayersIcon {
	position: absolute;
}

.star,
.verificationChangeOnCheckStarIcon,
.verificationChangeOnCheckWatchIcon,
.qualificationOnCheckIcon,
.qualificationQualifiedIcon,
.lawTypeTrianglesIcon,
.lawTypeLayersIcon {
	bottom: 0;
	right: 0;
}

.lawTypeTrianglesIconOnCheck {
	transform: translateX(-42.5%);
}

.lawTypeLayersIconOnCheck {
	transform: translate(-32%, -20%);
}

.lawTypeTrianglesIconVerified {
	transform: translateX(-10%);
}

.lawTypeLayersIconVerified {
	transform: translate(-7.5%, 5%);
}

.verification {
	&NotVerified {
		&Icon {
			color: get($colors, dark);
			right: -1%;
			bottom: -5%;
		}
	}
	&OnCheck {
		&Icon {
			right: -4.5%;
			bottom: -10%;
		}
	}
	&ChangeOnCheck {
		.decoration {
			transform: translate(55%, 40%);
		}
		&StarIcon, &WatchIcon {
			max-width: rem(90px);
			max-height: rem(90px);
		}
		&StarIcon {
			transform: translate(20%, -60%) rotate(0);
		}
		&WatchIcon {
			transform: translate(5%, 20%) rotate(-32.5deg);
			z-index: get($index, default);
		}
	}
}

.qualification {
	&NotQualified {
		&Icon {
			transform: translate(15%, 15%);
		}
	}
	&OnCheck, &Qualified {
		.decoration {
			transform: translate(65%, 60%) scale(1.5) rotate(45deg);
		}
		&Icon {
			transform: translate(5%, 20%);
		}
	}
}



