@import '../../../../../sass/utilites/variables';
@import '../../../../../sass/utilites/mixins';

.table {
	:global {
		table {
			@include media-breakpoint-up(sm) {
				@include font-size($font-size-md);
			}
			@include media-breakpoint-up(sm) {
				@include font-size($font-size-m);
			}
		}
	}
}
