@import '../../../../../sass/utilites/variables';
@import '../../../../../sass/utilites/mixins';

$padding-x:         rem(30px);
$padding-y-xl:         rem(20px);
$padding-y-md:         rem(15px);

.accordion {
	:global {
		.accordion {

			$arrow-icon-size:   rem(24px);

			&-item {
				background-color: $gray-400;
				border-radius: $border-radius-xxl;
				& + .accordion-item {
					margin-top: $padding-y-xl;
				}
			}
			&-button {
				width: 100%;
				text-align: left;
				padding: $padding-y-xl $padding-x * 2 + $arrow-icon-size $padding-y-xl $padding-x;
				font-weight: 500;
				position: relative;
				&:after {
					@include transition(transform);
					@include pseudo;
					@include background(get($pics, dropdown-arrow-black), $size: 90%);
					width: $arrow-icon-size;
					height: $arrow-icon-size;
					right: $padding-x;
					top: $padding-y-xl;
					will-change: transform;
					transform: rotate(180deg);
					@include media-breakpoint-up(xl) {
						opacity: .33;
					}
					@include media-breakpoint-down(xl) {
						border: $border-width solid rgba(0,0,0,.3);
						border-radius: 50%;
					}
				}
				&.collapsed {
					&:after {
						transform: rotate(0deg);
					}
				}
			}
			&-body {
				padding: 0 $padding-x $padding-y-xl;
			}
		}
	}
}

.ask {

	border-radius: $border-radius-xxl;

	position: relative;
	@include media-breakpoint-up(xl) {
		padding-top: $padding-y-xl;
		padding-bottom: $padding-y-xl;
	}
	@include media-breakpoint-between(sm, xl) {
		padding-top: $padding-y-md;
		padding-bottom: $padding-y-md;
	}
	@include media-breakpoint-up(sm) {
		background-color: $gray-400;
		padding-right: $padding-x;
		padding-left: $padding-x;
	}
	&Title {
		font-weight: 500;
		@include media-breakpoint-down(md) {
			@include font-size($font-size-m)
		}
	}
	&Icon {
		text-align: right;
		svg {
			@include media-breakpoint-up(xl) {
				width: rem(125px);
				height: rem(162px);
				margin-top: - rem(55px);
				margin-bottom: - $padding-y-xl;
				transform: translateY($padding-y-xl * .75);
			}
			@include media-breakpoint-down(xl) {
				width: rem(70px);
				height: rem(90px);
				margin-top: - rem(25px);
				margin-bottom: - $padding-y-md;
			}
		}
	}
}

:global {
	:local(.resultsTitle) {
		svg {
			flex: 0 0 auto;
			width: rem(26px);
			height: rem(26px);
			margin-right: rem(12px);
		}
		&.theme {
			&--info {
				svg {
					fill: get($colors, primary);
				}
			}
			&--warning {
				svg {
					fill: get($colors, yellow);
				}
			}
			&--success {
				svg {
					fill: get($colors, green);
				}
			}
			&--danger {
				svg {
					fill: get($colors, danger);
				}
			}
		}
	}
	:local(.askTitle) {
		&.text-md {
			@include media-breakpoint-down(md) {
				@include font-size($font-size-m)
			}
		}
	}
}



