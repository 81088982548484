@import '../../../../sass/utilites/variables';

.root {
	@include media-breakpoint-up(xl) {
		display: flex;
		flex-wrap: nowrap;
		height: 100vh;
		overflow: hidden;
	}
	&:after {
		transition-property: opacity, visibility;
		transition-duration: #{$transition-duration}s;
		@include pseudo-wrapper;
		background-color: rgba(0,0,0, .4);
		z-index: get($index, default);
		opacity: 0;
		visibility: hidden;
	}
}


:global {
	:local(.root) {
		&.is-backdrop {
			&:after {
				opacity: 1;
				visibility: visible;
			}
		}
	}
}
