@import '../../../../sass/utilites/variables';

.card {
	position: relative;
	min-height: rem(200px);
	:global {
		.playIcon {
			width: rem(100px);
			height: rem(100px);
		}
	}
}

.borrower {
	:global {
		.box {
			@include media-breakpoint-up(lg) {
				min-height: rem(130px);
			}
		}
	}
}

.description {
	@include media-breakpoint-up(xl) {
		@include font-size($font-size-md);
	}
	@include media-breakpoint-down(xl) {
		@include font-size($font-size-sm);
	}
}

.box {

	border-radius: $border-radius-xxl;
	@include media-breakpoint-up(xl) {
		padding: rem(20px);
	}
	@include media-breakpoint-down(xl) {
		padding: rem(16px);
	}
	@include media-breakpoint-between(sm, xl) {
		@include font-size($font-size-m);
	}
	@include media-breakpoint-up(sm) {
		background-color: $gray-400;
	}
	@include media-breakpoint-down(sm) {
		background-color: get($colors, white);
	}
	&Title {
		@include media-breakpoint-up(xl) {
			@include font-size($font-size-m);
		}
		@include media-breakpoint-between(sm, xl) {
			@include font-size($font-size-extra-small);
		}
		color: get($colors, dark);
	}
}

.goalEnd {
	@include media-breakpoint-up(md) {
		@include font-size($font-size-sm);
	}
	@include media-breakpoint-down(md) {
		@include font-size($font-size-extra-small-up);
	}
}

.document {
	background-color: $gray-400;
}

