@import '../../../../sass/utilites/variables';
@import '../../../../sass/utilites/mixins';

$box-size-m: rem(28px);
$box-size-sm: rem(38px);
$box-size-md: rem(55px);

.box {
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.sm {
	width: $box-size-sm;
	height: $box-size-sm;
	:global {
		svg {
			width: 60%;
			height: 60%;
		}
	}
}

.sm, .m {
	border-radius: $border-radius-lg;
}

.md {

	border-radius: $border-radius-xl;
	@include media-breakpoint-up(xl) {
		width: $box-size-md;
		height: $box-size-md;
	}
	@include media-breakpoint-between(lg, xl) {
		width: $box-size-md * .75;
		height: $box-size-md * .75;
	}
	@include media-breakpoint-down(lg) {
		width: $box-size-md * .645;
		height: $box-size-md * .645;
	}
}

.md, .m {
	:global {
		svg {
			width: 50%;
			height: 50%;
		}
	}
}

.m {
	width: $box-size-m;
	height: $box-size-m;
}
