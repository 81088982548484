@import '../../../../../sass/utilites/variables';
@import '../../../../../sass/utilites/mixins';

.root {
	position: relative;
	:global {
		.dropdown-toggle {


			&[aria-expanded='true'] {
				background-color: get($colors, white);
				border-color: $gray-200;
			}
		}
	}
}

:global {
	:local(.icon) {
		position: relative;
		&:after {
			@include pseudo;
			transition-property: transform;
			transition-duration: #{$transition-duration}s;
			width: 12px;
			height: 12px;
			right: 17.5%;
			bottom: 22.5%;
			background-color: get($colors, danger);
			border-radius: 50%;
			z-index: get($index, default);
			border: $border-width * 2 solid get($colors, white);
			transform: scale(0);
		}
		&.is-active {
			&:after {
				transform: scale(1);
			}
		}
	}
}



.dropdown {
	$maxHeight: rem(350px);
	$padding-y: rem(10px);

	max-height: $maxHeight;
	width: rem(330px);
	background-color: get($colors, white);
	border: $border-width solid $gray-200;
	border-radius: $border-radius-lg;
	padding: $padding-y 0 $padding-y / 2;

	:global {
		[data-simplebar] {
			max-height: $maxHeight - $padding-y * 2;
		}
		.simplebar-track {
			border-radius: $border-radius;
			&.simplebar-vertical {
				width: 4px;
				right: 4px;
			}
		}
	}
}

.list {
	@include font-size(15px);
	width: 100%;
}

$status-icon-size: rem(26px);

.item {
	display: flex;
	align-items: center;
	position: relative;
	padding: rem(5px) 0 rem(5px) rem(10px);

	&:not(:last-of-type) {
		padding-bottom: $paragraph-margin-bottom;
		&:after {
			@include pseudo;
			right: 0;
			bottom: 0;
			height: $border-width;
			background-color: get($colors, light);
			left: calc(#{$status-icon-size} + #{$grid-gutter-width / 2});
		}
	}
	:global {
		a {
			@include transition(color);
			color: get($colors, primary);
			text-decoration: underline;
		}
	}

}

:global {
	:local(.item) {
		&:not(.is-viewed) {
			cursor: pointer;
		}
		&.is-viewed {
			cursor: default;
		}
	}
}

.icon {
	width: $status-icon-size;
	height: $status-icon-size;
}

.remove {
	@include transition(color);
	width: rem(16px);
	height: rem(16px);
	display: inline-flex;
	padding: rem(2px);
	&:hover {
		color: get($colors, black);
	}
}

.text {

}

.message {
	position: relative;
	padding-right: rem(20px);
}

.read {
	display: block;
	position: absolute;
	top: 50%;
	transform: translateY(-50%) scale(1);
	right: 0;
	margin-top: - rem(4px);
	width: rem(15px);
	height: rem(15px);
	color: get($colors, success);
}

.preloader {
	$preloader-height: 4px;
	$preloader-animation-duration: 2.1;

	margin-top: - rem(7px);
	position: relative;
	height: $preloader-height;
	display: block;
	width: 95%;
	margin-left: auto;
	margin-right: auto;
	background-color: $gray-200;
	border-radius: $preloader-height / 2;
	overflow: hidden;
	&Inner {
		background-color: get($colors, primary);
		&:before, &:after {
			@include pseudo;
			background-color: inherit;
			top: 0;
			left: 0;
			bottom: 0;
			will-change: left, right;
		}
		&:before {
			animation: indeterminate #{$preloader-animation-duration}s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
		}
		&:after {
			animation: indeterminate-short #{$preloader-animation-duration}s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
			animation-delay: #{$preloader-animation-duration / 2}s;
		}
	}
}

@keyframes indeterminate{0%{left:-35%;right:100%}60%{left:100%;right:-90%}100%{left:100%;right:-90%}}
@keyframes indeterminate-short{0%{left:-200%;right:100%}60%{left:107%;right:-8%}100%{left:107%;right:-8%}}
