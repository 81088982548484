@import '../../../../../../sass/utilites/variables';

.section {
	min-height: rem(200px);
	:global {
		.text-default {
			@include media-breakpoint-down(sm) {
				@include font-size($font-size-m)
			}
		}
	}
}
