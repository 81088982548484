@import '../../utilites/variables';


$range-height-offset:    #{($range-height - $rail-height) / 2};
$handle-border-color:     #7CCDFF;

.rc-slider {
	position: relative;
	height: $range-height;
	padding: $range-height-offset 0;
	width: 100%;
	border-radius: $range-border-radius;
	touch-action: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	* {
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}
	&-rail {
		position: absolute;
		width: 100%;
		background-color: $gray-500;
		height: $rail-height;
		border-radius: $range-border-radius;
	}
	&-track {
		position: absolute;
		left: 0;
		height: $rail-height;
		border-radius: $range-border-radius;
		background-color: get($colors, primary);
	}
	&-handle {
		position: absolute;
		width: $range-height;
		height: $range-height;
		margin-top: - $range-height-offset;
		cursor: grab;
		border-radius: 50%;
		border: solid $rail-height / 2 $handle-border-color;
		background-color: get($colors, primary);
		touch-action: pan-x;
		&-click-focused:focus {
			border-color: #96dbfa;
			box-shadow: unset;
		}
		&:active {
			box-shadow: 0 0 $rail-height / 2 $handle-border-color;
			cursor: grabbing;
		}
	}
	&-mark {
		position: absolute;
		top: 18px;
		left: 0;
		width: 100%;
		font-size: 12px;
		&-text {
			position: absolute;
			display: inline-block;
			vertical-align: middle;
			text-align: center;
			cursor: pointer;
			color: #999;
			&-active {
				color: #666;
			}
		}
	}
	&-step {
		position: absolute;
		width: 100%;
		height: $rail-height;
		background: transparent;
	}
	&-dot {
		position: absolute;
		bottom: 0;
		width: $rail-height;
		height: $rail-height;
		margin-left: -$rail-height / 2;
		background-color: $gray-500;
		cursor: pointer;
		border-radius: 50%;
		vertical-align: middle;


		&-active {
			border-color: #96dbfa;
		}
		&-reverse {
			margin-right: -4px;
		}
	}
	&-disabled {
		background-color: $gray-500;
		.rc-slider-track {
			background-color: #ccc;
		}
		.rc-slider-handle,
		.rc-slider-dot {
			border-color: #ccc;
			box-shadow: none;
			background-color: #fff;
			cursor: not-allowed;
		}
		.rc-slider-mark-text,
		.rc-slider-dot {
			cursor: not-allowed !important;
		}
	}
	&-vertical {
		width: 14px;
		height: 100%;
		padding: 0 5px;
		.rc-slider {
			&-rail {
				height: 100%;
				width: 4px;
			}
			&-track {
				left: 5px;
				bottom: 0;
				width: 4px;
			}
			&-handle {
				margin-left: -5px;
				touch-action: pan-y;
			}
			&-mark {
				top: 0;
				left: 18px;
				height: 100%;
			}
			&-step {
				height: 100%;
				width: 4px;
			}
			&-dot {
				left: 2px;
				margin-bottom: -4px;
				&:first-child, &:last-child {
					margin-bottom: -4px;
				}
			}
		}
	}
	&-tooltip {
		position: absolute;
		left: -9999px;
		top: -9999px;
		visibility: visible;
		box-sizing: border-box;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		&-zoom-down {
			&-enter,
			&-appear {
				animation-duration: 0.3s;
				animation-fill-mode: both;
				display: block !important;
				animation-play-state: paused;
				transform: scale(0, 0);
				animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
				&.rc-slider-tooltip-zoom-down-enter-active,
				&.rc-slider-tooltip-zoom-down-appear-active {
					animation-name: rcSliderTooltipZoomDownIn;
					animation-play-state: running;
				}
			}
			&-leave {
				animation-duration: 0.3s;
				animation-fill-mode: both;
				display: block !important;
				animation-play-state: paused;
				animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
				&.rc-slider-tooltip-zoom-down-leave-active {
					animation-name: rcSliderTooltipZoomDownOut;
					animation-play-state: running;
				}
			}
		}
		&-hidden {
			display: none;
		}
		&-placement {
			&-top {
				padding: $rail-height 0 #{$rail-height * 2} 0;
				.rc-slider-tooltip-arrow {
					bottom: $rail-height;
					left: 50%;
					margin-left: -$rail-height;
					border-width: $rail-height $rail-height 0;
					border-top-color: #6c6c6c;
				}
			}
		}
		&-inner {
			padding: 6px 2px;
			min-width: 24px;
			height: 24px;
			font-size: 12px;
			line-height: 1;
			color: #fff;
			text-align: center;
			text-decoration: none;
			background-color: #6c6c6c;
			border-radius: $range-border-radius;
			box-shadow: 0 0 4px #d9d9d9;
		}
		&-arrow {
			position: absolute;
			width: 0;
			height: 0;
			border-color: transparent;
			border-style: solid;
		}
	}
}

@keyframes rcSliderTooltipZoomDownIn {
	0% {
		opacity: 0;
		transform-origin: 50% 100%;
		transform: scale(0, 0);
	}
	100% {
		transform-origin: 50% 100%;
		transform: scale(1, 1);
	}
}
@keyframes rcSliderTooltipZoomDownOut {
	0% {
		transform-origin: 50% 100%;
		transform: scale(1, 1);
	}
	100% {
		opacity: 0;
		transform-origin: 50% 100%;
		transform: scale(0, 0);
	}
}

