@import '../../../../../sass/utilites/variables';

.container {
	min-height: rem(200px);
	:global {
		table {
			table-layout: auto;
		}
	}
}

.table {
	@include media-breakpoint-between(lg, hd) {
		$cell-spacer: rem(10px);

		thead th,
		tbody tr td {
			&:first-of-type {
				padding-right: $cell-spacer;
			}
			&:last-of-type {
				padding-left: $cell-spacer;
			}
			&:not(:first-of-type):not(:last-of-type) {
				padding-left: $cell-spacer;
				padding-right: $cell-spacer;
			}
		}

	}
	&Loans {
		@include media-breakpoint-between(lg, lt) {

			table {
				width: rem(1400px);
			}
		}
	}
}

.simplebar {
	background-color: $gray-300;
	border-radius: $border-radius-xxl;
}

:global {
	:local(.table) {
		&.is-horizontally-scrolled {
			table {
				width: rem(1400px);
			}
		}
	}
}
