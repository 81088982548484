@import '../../../../sass/utilites/variables';

.box {
	background-color: get($colors, white);
	border-radius: $border-radius-xl;
	padding: rem(20px);
}

.avatar {
	border-radius: 50%;
	background-color: $gray-300;
	overflow: hidden;
}
