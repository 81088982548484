@import '../../../../sass/utilites/variables';
@import '../../../../sass/utilites/mixins';
@import 'node_modules/bootstrap/scss/vendor/rfs';

.root {
	transition-duration: #{$transition-duration}s;
	display: inline-flex;
	line-height: rem(30px);
	justify-content: center;
	align-items: center;
	user-select: none;
	position: relative;
	@include media-breakpoint-up(xl) {
		@include font-size($font-size-sm);
	}
	@include media-breakpoint-down(xl) {
		@include font-size($font-size-m);
	}
}

.contained, .outlined {
	transition-property: background-color, color, opacity;
	min-width: rem(120px);
	text-align: center;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	font-weight: 500;
	border-radius: $border-radius-pill;
	@include media-breakpoint-up(lg) {
		padding: rem(9px) rem(25px);
	}
	@include media-breakpoint-down(lg) {
		padding: rem(3px) rem(20px);
	}
	svg {
		margin-top: - $border-width;
		@include transition(transform);
	}
	@include media-breakpoint-up(xl) {
		&:not(:disabled) {
			&:hover {
				color: get($colors, white);
				svg {
					transform: translateX(rem(3px));
				}
			}
		}
	}
	&:disabled {
		cursor: default;
	}
}

.contained {
	background-color: get($colors, primary);
	color: get($colors, white);
	@include media-breakpoint-up(xl) {
		&:not(:disabled):hover {
			background-color: get($colors, secondary);
		}
	}
	&:disabled {
		background-color: $gray-500;
	}
}

.outlined {
	box-shadow: 0 0 0 $border-width get($colors, primary);
	color: get($colors, primary);
	background: transparent;
	@include media-breakpoint-up(xl) {
		&:not(:disabled):hover {
			background-color: get($colors, primary);
		}
	}
	&:disabled {
		box-shadow: 0 0 0 $border-width $gray-500;
		color: get($colors, dark);
	}
}

.icon, .danger, .success {
	border-radius: $border-radius-xl;
}

.icon {
	transition-property: border-color, background-color;
	transition-duration: #{$transition-duration}s;
	padding: rem(3px) rem(8px);
	color: get($colors, dark);

	border: $border-width solid $body-bg;
	min-height: rem(38px);
	min-width: rem(38px);
	&:hover {
		background-color: get($colors, white);
		border-color: $gray-200;
	}
}

.danger {
	@include font-size($font-size-extra-small-up);
	border: $border-width solid get($colors, danger);
	padding: rem(6px) rem(10px);
	font-weight: 500;
	color: get($colors, danger);
	&:hover {
		color: get($colors, white);
		background-color: get($colors, danger);
	}
	.content {
		display: flex;
		align-items: center;
	}
	:global {
		.exclamation-icon {
			margin-right: rem(8px);
			width: rem(18px);
			height: rem(18px);
		}
	}
}

.success {
	transition-property: box-shadow, background-color;
	padding: rem(10px) rem(20px);
	background-color: get($colors, green);
	font-weight: 500;
	color: get($colors, white);
	&:not(:disabled):hover {
		box-shadow: 0 rem(7px) rem(26px) 0 $shadow-color;
		background-color: darken(get($colors, green), 5%);
	}

}

.simple {}

