@import '../../../../sass/utilites/variables';
@import '../../../../sass/utilites/mixins';

.root {
	@include media-breakpoint-up(xl) {
		padding-top: rem(20px);
		padding-bottom: rem(30px);
	}
	:global(.button) {
		min-width: initial;
		width: 100%;
		@include media-breakpoint-up(xl) {
			padding: rem(8px) 0;
		}
		@include media-breakpoint-between(sm, xl) {
			padding: rem(6px) rem(15px);
		}
	}

}

.title {
	font-weight: 700;
	@include media-breakpoint-down(xl) {
		white-space: nowrap;
	}
	@include media-breakpoint-down(sm) {
		width: 100%;
		text-align: right;
		padding-bottom: rem(12px);
	}
}

.inner {

	@include media-breakpoint-up(sm) {
		padding: rem(20px) rem(15px) rem(20px) rem(20px);
	}
	@include media-breakpoint-up(xl) {
		box-shadow: get($shadows, regular);
		background-color: get($colors, white);
		border-radius: $border-radius-xl;
	}
	@include media-breakpoint-between(sm, xl) {
		background-color: $gray-300;
		border-radius: $border-radius-xxl;
	}
}

.buttons {
	@include media-breakpoint-up(xl) {
		width: 100%;
	}
	:global {
		@include media-breakpoint-down(sm) {
			.button {
				padding-top: rem(3px);
				padding-bottom: rem(3px);
			}
		}
	}
}

