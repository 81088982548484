@import '../../../sass/utilites/variables';
@import '../../../sass/utilites/mixins';

.root {
	position: relative;
	min-height: 100vh;

}


