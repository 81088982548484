@import 'utilites/variables';
@import 'utilites/mixins';

$animation-shift:       rem(20px);

.animation {
	&--slide {
		&-x {
			&-enter {
				opacity: 0;
				transform: translateX(-#{$animation-shift});
			}
			&-enter-active,
			&-exit-active {
				transition-property: opacity, transform;
				transition-duration: #{$transition-duration}s;
			}

			&-enter-active {
				opacity: 1;
				transform: translateX(0);
			}

			&-exit {
				opacity: 1;
			}

			&-exit-active {
				opacity: 0;
				transform: translateX($animation-shift);
			}
		}
		&-y {
			&-enter {
				opacity: 0;
				transform: translateY($animation-shift);
			}
			&-enter-active,
			&-exit-active {
				transition-property: opacity, transform;
				transition-duration: #{$transition-duration}s;
			}

			&-enter-active {
				opacity: 1;
				transform: translateY(0);
			}

			&-exit {
				opacity: 1;
			}

			&-exit-active {
				opacity: 0;
				transform: translateY(-#{$animation-shift});
			}
		}
	}
	&--fade {
		&-in {
			&-enter {
				opacity: 0;
			}
			&-enter-active,
			&-exit-active {
				transition-property: opacity;
				transition-duration: #{$transition-duration}s;
			}
			&-enter-active {
				opacity: 1;
			}
			&-exit {
				opacity: 1;
			}
			&-exit-active {
				opacity: 0;
			}
		}
	}
	&--scale {
		&-enter {
			transform: scale(0);
		}
		&-enter-active,
		&-exit-active {
			transition-property: transform;
			transition-duration: #{$transition-duration}s;
		}
		&-enter-active {
			transform: scale(1);
		}
		&-exit {
			transform: scale(1);
		}
		&-exit-active {
			transform: scale(0);
		}
	}
}

