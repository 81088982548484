@import '../../../../../sass/utilites/variables';
@import '../../../../../sass/utilites/mixins';

.root {
	padding-left: rem(20px);
	a {
		@include transition(color);
	}
}




