@import '../../../../sass/utilites/variables';

$email-offset-bottom: rem(40px);

.root {
  flex: 0 0 auto;
  background-color: $gray-200;
  position: relative;
  z-index: get($index, md);
  @include media-breakpoint-up(xl) {
    padding: rem(40px) 0 calc(2vh + #{$email-offset-bottom});
  }
  @include media-breakpoint-down(xl) {
    padding: rem(20px) 0;
  }
}

.social {
  position: relative;
  @include media-breakpoint-down(xl) {
    display: flex;
    align-items: center;
    .email {
      margin-left: rem(15px);
    }
  }
  @include media-breakpoint-between(lg, xl) {
    justify-content: flex-end;
  }
}

@include media-breakpoint-up(xl) {
  .email {
    position: absolute;
    bottom: - $email-offset-bottom;
    left: 0;
  }
}

.logo {
  @include media-breakpoint-down(xl) {
    max-width: rem(105px);
  }
}


.link {
  @include media-breakpoint-down(sm) {
    @include font-size($font-size-extra-small)
  }
}

.login {
  @include media-breakpoint-down(xl) {
    @include font-size($font-size-extra-small);
    line-height: rem(20px);
  }
}

.phone {
  transition: color .25s ease;
  font-size: 1rem;
  color: #5BC1FF;
  display: inline-flex;
  align-items: center;

  &:hover {
    color: #0087DA;
  }

  small {
    color: #96A6B8;
  }
}

.address {
  color: #96A6B8;
  display: flex;
  align-items: center;
  @include media-breakpoint-down(md) {
    justify-content: flex-end;
  }
}

.rights {
  color: #96A6B8;
  margin: 1rem 0 0 0;
}

.footerRow {
  display: grid;
  grid-template-columns: 1.5fr 1fr 4.5fr 1.5fr;
  gap: 1rem;
  @include media-breakpoint-down(xl) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: start;
  }
  @include media-breakpoint-down(md) {
    grid-template-columns: 1fr 1fr;
    align-items: start;
    * {
      font-size: 12px;
    }
  }
}

.footerSocialGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include media-breakpoint-down(xl) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  @include media-breakpoint-down(md) {
    * {
      font-size: 12px;
    }
  }
}
