@import '../../../../sass/utilites/variables';
@import '../../../../sass/utilites/mixins';

.close {
	@include transition(color);
	position: absolute;
	right: $toastify-padding;
	top: $toastify-padding;
	width: rem(20px);
	height: rem(20px);
	padding: rem(4px);
	svg {
		vertical-align: top;
	}
	&:hover {
		color: get($colors, black);
	}
}
