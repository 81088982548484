@import '../../../../sass/utilites/variables';
@import '../../../../sass/utilites/mixins';

.root {
	width: 100%;
	background-color: get($colors, white);
	box-shadow: 0 rem(7px) rem(250px) rem(-8px) rgba(207, 216, 238, .56);
	border-radius: $border-radius-pill;
	@include media-breakpoint-up(xxl) {
		padding: rem(50px) 3.15vw;
	}
	@include media-breakpoint-between(md, xxl) {
		padding: rem(40px);
	}
	@include media-breakpoint-down(md) {
		padding: rem(24px) rem(16px) rem(40px) rem(16px);
	}
	a {
		transition-duration: #{$transition-duration}s;
	}
	:global {
		.field {
			@include placeholder {
				@include font-size($font-size-sm)
			}
		}
	}
}

.title {
	@include font-size($font-size-md);
	font-weight: 500;
	text-align: center;
	margin-bottom: 0;
	padding-left: rem(20px);
	padding-right: rem(20px);
	@include media-breakpoint-up(sm) {
		padding-bottom: rem(30px);
	}

	@include media-breakpoint-down(sm) {
		padding-bottom: rem(24px);
	}
}

.text {
	@include font-size($font-size-sm)
}

.spinner {
	position: absolute;
	right: rem(20px);
	top: rem(20px);
}

.reSendTimer {
	&UnActive {
		cursor: default;
	}
	&Active {
		@include transition(color);
		cursor: pointer;
		&:hover {
			color: get($colors, black);
		}
	}
}

.wrap {
	position: relative;
	z-index: get($index, sm);
}

.isEnter {
	opacity: 0;
	transform: translateY(-10%);
}

.isExit {
	opacity: 1;
	transform: translateY(0);
}

.isEnterActive {
	opacity: 1;
	transform: translateY(0);
}

.isExitActive {
	opacity: 0;
	transform: translateY(10%);
}

.isEnterActive, .isExitActive {
	transition-property: opacity, transform;
	transition-duration: #{$transition-duration}s;
}

.formHolder {
	@include media-breakpoint-up(md) {
		padding: 0 rem(30px);
	}
}
