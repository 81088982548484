@import '../../../../sass/utilites/variables';
@import 'node_modules/bootstrap/scss/vendor/rfs';

.root {

}

.small {
	@include font-size($font-size-m)
}
