@use 'sass:math';
@import '../../../../sass/utilites/variables';
@import '../../../../sass/utilites/mixins';
@import 'node_modules/bootstrap/scss/vendor/rfs';

:global {
	:local(.root) {
		input[readonly] {
			pointer-events: none;
		}
		&.is-loading {
			position: relative;
			pointer-events: none;
			input {
				padding-right: calc(#{$spinner-width-sm} + #{$input-padding-x * 1.5});
			}
			.upload {
				&PlusIcon, &Label {
					visibility: hidden;
				}
				&ed {
					& > * {
						visibility: hidden;
					}
				}
			}
		}
		&.is-error {
			.upload {
				&Main {
					border-color: get($colors, danger);
				}
				&PlusIcon, &Label {
					color: get($colors, danger);
				}
			}
		}
	}
}

.root {
	display: block;
	position: relative;
	@include font-size($font-size-sm);
	padding-bottom: $error-spacer;
	& + .root {
		@include media-breakpoint-up(xl) {
			margin-top: rem(18px);
		}
		@include media-breakpoint-between(sm, xl) {
			margin-top: rem(12px);
		}
	}

	:global {
		[class*='spinner-'] {
			@include media-breakpoint-up(xl) {
				bottom: $input-padding-y * 1.2;
				right: $input-padding-x * 1.2;
			}
			@include media-breakpoint-down(xl) {
				bottom: $input-padding-y  * .85;
				right: $input-padding-x * .85;
			}
			position: absolute;
			z-index: get($index, default);
		}

		.#{$selectClassName} {

			&__container {
				cursor: pointer;
			}
			&__control {
				min-height: auto;
				border-color: $input-border-color;
				border-radius: $input-border-radius;
				box-shadow: none;
				@include media-breakpoint-up(xl) {
					padding: $input-padding-y / 2 $input-padding-x;
				}
				@include media-breakpoint-down(xl) {
					padding: $input-padding-y / 5.375 $input-padding-x;
				}
				&:hover {
					border-color: get($colors, primary);
					cursor: pointer;
					outline: none;
				}
				&--menu-is-open {
					.#{$selectClassName}__indicator {
						transform: rotate(180deg);
					}
				}
			}
			&__value {
				&-container {
					padding: 0 rem(8px) 0 0;
				}
			}
			&__indicator {
				@include transition(transform, #{$transition-duration / 2}s);
				padding: 8px 0;
				color: get($colors, dark);
				pointer-events: none;
				&-separator {
					display: none;
				}
			}
			&__indicators {
				transition-property: visibility, opacity;
				transition-duration: #{$transition-duration}s;
			}
			&__menu {
				box-shadow: none;
				&-list {
					border: $border-width solid $input-border-color;
					border-radius: $input-border-radius;
					padding-top: 0;
					padding-bottom: 0;
				}
			}
			&__option {
				@include transition(color);
				@include font-size($font-size-m);
				background-color: get($colors, white);
				color: get($colors, dark);
				padding: rem(10px) rem(16px);
				&:active {
					background-color: get($colors, white);
				}
				&:not(:last-child) {
					border-bottom: $border-width solid $gray-200;
				}
				&:hover {
					cursor: pointer;
				}
				&--is-selected, &:hover {
					color: get($colors, black);
				}
				&--is-selected {
					cursor: default;
				}
			}
		}
	}
}

.label, .control {
	font-size: inherit;
}

.label {
	font-weight: 500;
	color: get($colors, dark);
	margin-bottom: rem(5px);
}

.field, .upload, .uploaded {
	border: $border-width solid $input-border-color;
	border-radius: $input-border-radius;
	width: 100%;
	@include media-breakpoint-up(xl) {
		padding: $input-padding-y $input-padding-x;
	}
	@include media-breakpoint-down(xl) {
		padding: $input-padding-y / 1.475 $input-padding-x;
	}
	&:focus, &:focus-visible {
		box-shadow: none;
		border-color: $input-focus-border-color;
		outline: none;
	}
}

.field {
	@include transition(border-color);
	display: block;
	font-weight: normal;
	color: get($colors, black);
	background-color: get($colors, white);
	background-clip: padding-box;
	appearance: none;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	@include placeholder {
		color: get($colors, dark);
	}
	&[type='file'] {
		overflow: hidden;
		&:not(:disabled):not([readonly]) {
			cursor: pointer;
		}
	}
}

button.field {
	&:hover {
		border-color: get($colors, primary);
	}
	&:focus {
		border-color: $input-border-color;
	}
}

.note {
	@include font-size($font-size-extra-small);
	padding-top: rem(5px);
	padding-bottom: rem(5px);
	display: block;
}
/*field end*/

$checkbox-size:         24px;
$checkbox-inner-size:   $checkbox-size / 2;

.checkbox, .radio {
	@include font-size($font-size-m);
	user-select: none;
	display: flex;
	a {
		@include transition(color);
	}
	&Label {
		@include font-size($font-size-m);
		position: relative;
		padding-left: $checkbox-size;
		cursor: pointer;
		min-height: $checkbox-size;
		&:before, &:after {
			@include pseudo;
		}
		&:before {
			@include transition(border-color, #{$transition-duration}s);
			width: $checkbox-size;
			height: $checkbox-size;
			left: 0;
			top: 0;
			border: $border-width solid $border-color;
		}
		&:after {
			@include transition(transform, #{$transition-duration / 2}s);
			height: $checkbox-inner-size;
			width: $checkbox-inner-size;
			background-color: get($colors, primary);
			left: $checkbox-size / 4;
			top: $checkbox-size / 4;
			transform: scale(0);
		}
		@include interaction('mouse') {
			&:hover {
				&:before {
					border-color: get($colors, primary);
				}
			}
		}
	}
	&Field {
		position: absolute;
		z-index: get($index, negative);
		opacity: 0;
		visibility: hidden;
		&:disabled {
			& + .checkboxLabel,
			& + .radioLabel {
				opacity: .5;
				pointer-events: none;
			}
		}

		&:checked + .checkboxLabel,
		&:checked + .radioLabel {
			&:after {
				transform: scale(1);
			}
		}
	}
}

.checkbox {
	&Label {
		&:before {
			border-radius: $border-radius-lg;
		}
		&:after {
			border-radius: $border-radius-sm;
		}
	}
}

:global {
	:local(.root) {
		&.is-disabled {
			.checkbox {
				opacity: .5;
				pointer-events: none;
			}
		}
	}
}

.radio {
	&Label {
		&:before, &:after {
			border-radius: 50%;
		}
	}
}
/*checkbox and radio end*/

.fieldPassword {
	@include font-size($font-size-md);
	font-family: 'password', sans-serif;
	line-height: rem(20px);
	@include placeholder {
		@include font-size($font-size-sm);
	}
	&IsFilled {
		padding-right: rem(60px);
	}
	&IsVisible {
		@include font-size($font-size-sm);
		font-family: $font-family-base;
	}
}

.visibilityButton {
	transition-property: opacity, hidden, color;
	transition-duration: #{$transition-duration}s;
	position: absolute;
	bottom: 0;
	height: 100%;
	display: block;
	right: 0;
	padding: 0 rem(16px);
	color: get($colors,dark);
	opacity: 0;
	visibility: hidden;
	&IsVisible {
		opacity: 1;
		visibility: visible;
	}
	&:hover {
		color: darken(get($colors, dark), 10%);
	}
}

.visibilityIcon {
	width: rem(25px);
	height: rem(25px);
}

.isEnter {
	opacity: 0;
	transform: scale(.15);
}

.isExit {
	opacity: 1;
	transform: scale(1);
}

.isEnterActive {
	opacity: 1;
	transform: scale(1);
}

.isExitActive {
	opacity: 0;
	transform: scale(1.25);
}

.isEnterActive, .isExitActive {
	transition-property: opacity, transform;
	transition-duration: #{$transition-duration}s;
}
/*password field end*/

/*file upload start*/

.upload, .uploaded {
	height: 100%;
	display: flex;
	align-items: center;
}

.upload {
	@include transition(color);
	position: relative;
	cursor: pointer;

	@include media-breakpoint-up(sm) {
		padding-top: rem(25px);
		padding-bottom: rem(25px);
		flex-direction: column;
		justify-content: center;
	}
	@include media-breakpoint-down(sm) {
		padding-top: rem(18px);
		padding-bottom: rem(17px);
	}
	&:hover {
		color: get($colors, primary);
	}
	:global {
		.upload {
			&PlusIcon {
				width: rem(38px);
				height: rem(38px);
				@include media-breakpoint-up(sm) {
					margin-bottom: rem(5px);
				}
			}
			&Label {
				display: block;
			}
		}
	}
}

.fileType {
	padding-bottom: 0;
	margin-bottom: $input-padding-y;

	:global {
		.errorMessage {
			margin-bottom: - $error-spacer;
		}
		.loader {
			width: rem(40px);
		}
	}
}

.uploaded {
	$picture-size-lg: rem(70px);
	$picture-size-sm: rem(40px);

	background-color: $gray-400;
	border-color: $gray-400;
	&Picture {
		border-radius: 50%;
		overflow: hidden;
		position: relative;
		@include media-breakpoint-up(xl) {
			width: $picture-size-lg;
		}
		@include media-breakpoint-between(sm, xl) {
			width: $picture-size-sm * 1.5;
		}
		@include media-breakpoint-down(sm) {
			width: $picture-size-sm;
		}
		&:before {
			@include pseudo-wrapper;
			background-color: $gray-400;
			border-radius: inherit;
		}
	}
	&Controls {
		position: absolute;
		right: $input-padding-x / 2;
		top: $input-padding-y / 2;
		color: $gray-500;
		@include media-breakpoint-down(xl) {
			overflow: hidden;
		}
		&Delete, &Change {
			@include transition(color);
			padding: rem(4px);
			position: relative;
			cursor: pointer;
			@include media-breakpoint-up(xl) {
				&:hover {
					color: get($colors, dark);
					.uploadedControlsNote {
						opacity: 1;
						visibility: visible;
						@include media-breakpoint-up(xl) {
							transform: translate(-50%, 0);
						}
						@include media-breakpoint-down(xl) {
							transform: translate(0, 0);
						}
					}
				}
			}
		}
		&Note {
			transition-property: visibility, opacity, transform;
			transition-duration: #{$transition-duration}s;
			@include font-size($font-size-extra-small-up);
			position: absolute;
			cursor: pointer;
			white-space: nowrap;
			display: inline-block;
			top: 100%;
			opacity: 0;
			visibility: hidden;
			pointer-events: none;
			user-select: none;
			z-index: get($index, default);
			padding: rem(6px) rem(15px);
			background-color: get($colors, white);
			border: $border-width solid $border-color;
			border-radius: $border-radius-sm;
			@include media-breakpoint-up(xl) {
				left: 50%;
				transform: translate(-50%, rem(10px));
			}
			@include media-breakpoint-down(xl) {
				right: 0;
				transform: translate(0, rem(10px));
			}
		}
	}
}


:global {
	:local(.file) {
		&.is-loading {
			.file {
				&PlusIcon, &Label {
					opacity: 0;
					visibility: hidden;
				}
			}
			.uploaded > * {
				opacity: 0;
				visibility: hidden;
			}
		}
	}
}
/*file upload end*/

/*custom select start*/
.selectControl {
	&Icon {
		color: get($colors, dark);
		@include media-breakpoint-up(md) {
			width: rem(22px);
			height: rem(22px);
		}
		@include media-breakpoint-down(md) {
			width: rem(16px);
			height: rem(16px);
			margin-top: - rem(4px);
		}
	}
}
/*custom select end*/

/*textarea start*/
.textarea {
	min-height: rem(150px);
}
/*textarea end*/

/*error start*/
.error {
	@include font-size(get($font-size-extra-small));
	color: get($colors, danger);
	position: absolute;
	bottom: 0;
	left: 0;
	display: block;
}
/*error end*/

/*suggestions start*/
$bik-area-width: rem(90px);
$suggestion-padding-x: rem(16px);
$suggestion-padding-y: rem(10px);




.suggestions {
	position: relative;
	z-index: get($index, default);

	&Holder {
		overflow: hidden;
		position: absolute;
		left: 0;
		width: 100%;
		top: calc(100% + #{$border-width});
		background-color: get($colors, white);
		border: $border-width solid $input-border-color;
		border-radius: $input-border-radius;
		padding-bottom: rem(10px);
		:global {
			.simplebar {
				&-track {
					border-radius: $border-radius-lg;
					&.simplebar-vertical {
						width: 6px;
					}
				}
				&-scrollbar {
					&:before {
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
					}
				}
			}
		}
	}
	&Scroller {
		max-height: rem(200px);
		margin-right: rem(4px);
	}
	& + [class*='spinner-'] {
		bottom: 36.666%;
	}
}

.suggestion {
	&Item {
		@include font-size($font-size-m);
		display: flex;
		flex-wrap: wrap;
		cursor: pointer;
		padding: $suggestion-padding-y / 2 $suggestion-padding-x;
		color: get($colors, dark);
		&:hover {
			background-color: get($colors, light);
		}
	}
	&Note {
		@include font-size(13px);
		padding: $suggestion-padding-y ($spinner-width-sm * 2) $suggestion-padding-y $suggestion-padding-x;
		position: relative;
	}
	&Name {
		width: 100%;
		padding-bottom: rem(5px);
		color: get($colors, black);
	}
	&DesiredProp {
		width: $bik-area-width;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	&Address {
		width: calc(100% - #{$bik-area-width});
	}
}
/*suggestions end*/
