@import '../../utilites/variables';

$datepicker__background-color: get($colors, white);
$datepicker__border-color: $gray-200;
$datepicker__highlighted-color: #3dcc4a !default;
$datepicker__muted-color: #ccc !default;
$datepicker__selected-color: get($colors, primary);
$datepicker__text-color: get($colors, black);
$datepicker__header-color: #000 !default;
$datepicker__navigation-disabled-color: lighten(
  $datepicker__muted-color,
  10%
) !default;

$datepicker__border-radius: $border-radius-xl;

$datepicker__font-size: $font-size-base;
$datepicker__font-family: $font-family-base;
$datepicker__item-size: 32px;
$datepicker__margin: rem(20px);
$datepicker__navigation-button-size: 33px;
$datepicker__triangle-size: 8px !default;
