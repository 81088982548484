@import '../../../../../sass/utilites/variables';

.table {
	@include media-breakpoint-up(xl) {
		@include font-size($font-size-sm);
	}
	@include media-breakpoint-between(sm, xl) {
		@include font-size($font-size-m);
	}
	@include media-breakpoint-down(sm) {
		@include font-size($font-size-extra-small-up);
	}
	@include media-breakpoint-up(hd) {
		padding-right: 30%;
	}
	table {
		@include media-breakpoint-up(xl) {
			table-layout: fixed;
		}
		width: 100%;
	}
	td {
		vertical-align: top;
		padding-bottom: rem(15px);
		&:first-of-type {
			font-weight: 500;
			color: get($colors, dark);

			@include media-breakpoint-up(xl) {
				width: 30%;
				padding-right: rem(6px);
			}
			@include media-breakpoint-down(xl) {
				padding-right: rem(40px);
			}
		}
		&:last-of-type {
			@include media-breakpoint-up(xl) {
				width: 70%;
			}
		}
	}
}

.main {
	min-height: rem(200px);
	position: relative;
}

.qr {
	@include media-breakpoint-up(xl) {
		max-width: rem(250px);
	}
	@include media-breakpoint-between(sm, xl) {
		width: rem(230px);
		max-width: 100%;
	}
	img {
		@include media-breakpoint-down(sm) {
			width: rem(170px);
		}
		@include media-breakpoint-up(sm) {
			width: 100%;
		}
	}
}
