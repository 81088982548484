@import "../../../../sass/utilites/variables";
@import "../../../../sass/utilites/mixins";

:global {
	:local(.link) {
		transition-duration: #{$transition-duration}s;
		transition-property: background-color;
		background-color: transparent;
		padding: rem(14px) rem(20px);
		border-radius: $border-radius-lg;
		display: flex;
		@include media-breakpoint-down(xl) {
			color: get($colors, black);
			.icon {
				path {
					fill: get($colors, black);
				}
			}
		}
		&.is-active {
			@include media-breakpoint-up(xl) {
				background-color: get($colors, white);
				box-shadow: get($shadows, regular);
				color: get($colors, black);
				.path {
					&--default {
						opacity: 0;
						visibility: hidden;
					}
					&--active {
						opacity: 1;
						visibility: visible;
					}
				}
			}
			@include media-breakpoint-down(xl) {
				color: get($colors, dark);
				.path {
					fill: get($colors, dark);
				}
			}
		}
		&:not(.is-active) {
			@include media-breakpoint-up(xl) {
				&:hover {
					cursor: pointer;
					color: get($colors, primary);
					// 	path {
					// 		fill: get($colors, primary);
					// 	}
					.path {
						&--default {
							opacity: 0;
							visibility: hidden;
						}
						&--active {
							opacity: 1;
							visibility: visible;
						}
					}
				}
			}
		}
	}
}

.link {
	& + .link {
		margin-top: rem(4px);
		@include media-breakpoint-down(xl) {
			border-top: $border-width solid $border-color;
		}
	}
	:global {
		.name {
			padding-left: rem(15px);
			display: inline-block;
			padding-top: rem(2px);
		}
		.path {
			transition-duration: #{$transition-duration}s;
			transition-property: opacity, visibility;
			position: absolute;
			&--default {
				opacity: 1;
				visibility: visible;
			}
			&--active {
				opacity: 0;
				visibility: hidden;
			}
		}
	}
}
