@import '../../../../../../sass/utilites/variables';
@import '../../../../../../sass/utilites/mixins';

:global {
	:local(.filterButton) {
		position: relative;
		&:after {
			@include transition(transform, #{$transition-duration / 2}s, ease-in-out);
			@include background(get($pics, gear-primary), $size: 90%);
			@include pseudo;
			right: $spacer / 1.75;
			top: $spacer * 1.25;
			transform: translateY(-50%) scale(0) rotate(360deg);
			width: rem(20px);
			height: rem(20px);
		}
		&.is-involved {
			&:after {
				transform: translateY(-50%) scale(1) rotate(0);
			}
		}
	}
}

.filter {
	margin-bottom: $grid-gutter-width / 2;
	@include media-breakpoint-down(sm) {
		border-color: $border-color;
		border-style: solid;
		border-width: $border-width 0 $border-width 0;
		margin-left: -$grid-gutter-width / 2;
		margin-right: -$grid-gutter-width / 2;
		width: calc(100% + #{$grid-gutter-width});
	}

	&Button, &Sorting {
		@include media-breakpoint-down(md) {
			@include font-size($font-size-m)
		}
		@include media-breakpoint-down(sm) {
			color: get($colors, dark);
		}
	}
	&Button {
		@include media-breakpoint-down(sm) {
			background-color: transparent;
			border: 0;
		}
	}
	&Sorting {
		@include media-breakpoint-down(sm) {
			position: relative;
			&:after {
				@include pseudo;
				width: $border-width;
				height: 100%;
				right: 0;
				top: 0;
				background-color: $border-color;
			}
		}
		:global {
			.custom-select {
				&__control {
					@include media-breakpoint-down(sm) {
						background-color: transparent;
						border: 0;
					}
				}
				&__single-value {
					@include media-breakpoint-down(sm) {
						color: get($colors, dark);
					}
				}
				&__menu {
					@include media-breakpoint-down(sm) {
						margin-top: 0;
						width: calc(100% - #{$grid-gutter-width / 2});
						left: $grid-gutter-width / 2;
					}
					&-list {
						@include media-breakpoint-down(sm) {
							border-radius: 0;
						}
					}
				}
			}
		}
	}
}
