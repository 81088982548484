@import '../../../../sass/utilites/variables';
@import '../../../../sass/utilites/mixins';

$card-padding-x-xl: rem(30px);
$card-padding-y-xl: $card-padding-x-xl;

$card-padding-x-md: rem(20px);
$card-padding-y-md: $card-padding-x-md;

$card-padding-x-sm: $grid-gutter-width / 2;
$card-padding-y-sm: rem(18px);

.card {
	background-color: $gray-400;
	padding: $grid-gutter-width;
	border-radius: $border-radius-pill;
	position: relative;
	@include media-breakpoint-up(xl) {
		padding: $card-padding-y-xl $card-padding-x-xl;
	}
	@include media-breakpoint-between(sm, xl) {
		padding: $card-padding-y-xl $card-padding-x-md;
	}
	@include media-breakpoint-down(sm) {
		padding: $card-padding-y-sm $card-padding-x-sm;
		background-color: get($colors, white);
	}
	& + .card {
		margin-top: rem(20px);
	}
	:global {
		.accordion {
			&-button {

				display: block;
				width: 100%;
				text-align: left;
				position: relative;
				padding: rem(10px) rem(50px) rem(10px) 0;
				.heading {
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}

				&:after {
					@include transition(transform);
					@include pseudo;
					@include background(get($pics, dropdown-arrow-black), $size: 90%);
					width: rem(26px);
					height: rem(26px);
					right: 0;
					top: 50%;
					transform: translateY(-50%) rotate(-180deg);
					will-change: transform;
				}
				&.collapsed {
					&:after {
						transform: translateY(-50%) rotate(0);
					}
				}
			}
			&-item {
				&:not(last-of-type) {
					border-bottom: $border-width solid $gray-200;
				}
			}
		}
	}
}

.title {
	font-weight: 700;
	text-transform: uppercase;
	.dateBadge {
		font-weight: normal;
		text-transform: initial;
	}
}

.description {
	@include font-size($font-size-md);
	color: get($colors, dark);
}

.section {
	@include font-size($font-size-m);
}

.box {
	@include font-size($font-size-extra-small-up);
	padding: rem(12px);
	border-radius: $border-radius-xl;

}

.document {
	@include transition(background-color);
	@include font-size($font-size-extra-small-up);
	border-radius: $border-radius-xl;
	min-height: rem(62px);
	@include media-breakpoint-up(sm) {
		background-color: get($colors, white);
	}
	@include media-breakpoint-down(sm) {
		background-color: $body-bg;
	}
	@include media-breakpoint-up(xl) {
		&:hover {
			background-color: $gray-200;
		}
	}
}

.mediaPlaceholder, .mediaImage {
	background-color: lighten($gray-200, 2%);
	border-radius: $border-radius-pill;
	overflow: hidden;
}


.mediaPlaceholder {
	text-align: center;
	color: get($colors, dark);
	svg {
		max-width: rem(50px);
	}
	& > svg {
		left: 50%;
		transform: translateX(-50%);
	}
	a {
		@include transition(color);
		&:hover {
			color: get($colors, dark);
		}
	}
}


