@import '../../../../../sass/utilites/variables';
@import '../../../../../sass/utilites/mixins';

.root {
	padding: rem(12px);
	border-radius: $border-radius-xl;
	width: 100%;
	position: relative;
}

.white {
	background-color: get($colors, white);
}

.info {
	position: absolute;
	right: $grid-gutter-width / 3;
	top: $grid-gutter-width / 3;
	svg {
		fill: get($colors, info);
	}
	:global {
		.tooltip {
			max-width: rem(220px);
		}
	}
}

.pear {
	background-color: rgba(get($colors, pear), .08);
}

.turquoise {
	background-color: rgba(get($colors, turquoise), .07);
}
