@import '../../../sass/utilites/variables';
@import '../../../sass/utilites/mixins';

.view {
	min-height: 100vh;
	:global {
		.heading {
			@include font-size(35px);
			margin-bottom: rem(20px);
		}
	}
}


.icon {
	max-width: rem(75px);
}

.content {
	position: relative;
}

.background {
	background-image: url('/assets/images/not-found-background.png');
	background-position: center bottom;
	background-repeat: no-repeat;
	background-size: cover;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	opacity: .25;
}
