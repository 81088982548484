@import "base/root";
@import "base/reboot";
@import "animations";
@import "utilites/variables";

body {
	height: auto;
}

input[type="search"] {
	&::-webkit-search-decoration,
	&::-webkit-search-cancel-button,
	&::-webkit-search-results-button,
	&::-webkit-search-results-decoration {
		-webkit-appearance: none;
	}
}

.spinner--centered {
	position: absolute;
	left: calc(50% - #{$spinner-width / 2});
	top: calc(50% - #{$spinner-width / 2});
	z-index: get($index, md);
	&[class*="-sm"] {
		left: calc(50% - #{$spinner-width-sm / 2});
		top: calc(50% - #{$spinner-width-sm / 2});
	}
}

.btn-close {
	@include transition;
}

.modal {
	$modal-inner-padding-md: rem(50px);

	&-header {
		min-height: calc(
			#{$modal-header-padding-y * 2} + #{$spinner-height-sm} + #{$border-width}
		);

		@include media-breakpoint-down(sm) {
			padding-left: $grid-gutter-width / 2;
			padding-right: $grid-gutter-width / 2;
			background-color: $body-bg;
			border-radius: 0;
			border-bottom: $border-width solid $gray-200;
		}
		&-logo {
			width: rem(120px);
			height: rem(30px);
		}
	}
	&-body {
		padding-bottom: rem(60px);
		@include media-breakpoint-up(sm) {
			padding-top: 0;
		}
		@include media-breakpoint-down(sm) {
			padding-top: $grid-gutter-width / 2;
		}
		@include media-breakpoint-up(xl) {
			padding-left: $modal-inner-padding * 0.925;
			padding-right: $modal-inner-padding * 0.925;
		}
		@include media-breakpoint-between(sm, xl) {
			padding-left: $modal-inner-padding-md;
			padding-right: $modal-inner-padding-md;
		}
		@include media-breakpoint-down(sm) {
			padding-left: $grid-gutter-width / 2;
			padding-right: $grid-gutter-width / 2;
		}
	}
	&-dialog {
		@include media-breakpoint-up(xl) {
			max-width: $modal-md;
		}
		@include media-breakpoint-between(sm, xl) {
			max-width: $modal-sm;
		}
		@include media-breakpoint-down(sm) {
			margin: 0;
		}
	}
	&-content {
		@include media-breakpoint-down(sm) {
			border: 0 none;
			min-height: 100vh;
			border-radius: 0;
		}
	}
}

.text {
	&-m {
		@include font-size($font-size-m);
	}
	&-sm {
		@include media-breakpoint-up(sm) {
			@include font-size($font-size-sm);
		}
		@include media-breakpoint-down(sm) {
			@include font-size($font-size-m);
		}
	}
	&-md {
		@include font-size($font-size-md);
	}
	&-xl {
		@include font-size($font-size-xl);
	}
	&-default {
		@include font-size($font-size-base);
	}
	&-overflow,
	&-clamp {
		overflow: hidden;
	}
	&-overflow {
		white-space: nowrap;
		text-overflow: ellipsis;
		display: inline-block;
	}
	&-clamp {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		&--2 {
			-webkit-line-clamp: 2;
		}
	}
	&-jade {
		color: get($colors, jade);
	}
	&-yellow {
		color: get($colors, yellow);
	}
	&-green {
		color: get($colors, green);
	}
	&-weight {
		&-medium {
			font-weight: 500;
		}
	}
	&-nowrap {
		white-space: nowrap;
	}
}

.dropdown-menu {
	position: absolute;
	z-index: get($index, lg);
	background-clip: padding-box;
	display: none;
	top: 100%;
	right: 0;
	&.show {
		display: block;
	}
}

.default-marked-list {
	margin-left: rem(17px);
	li {
		list-style-type: disc;
		& + li {
			margin-top: rem(10px);
		}
	}
}
.colgroup {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

@media (min-device-pixel-ratio: 2) {
	html {
		font-size: 15px;
	}
}

@include media-breakpoint-between(sm, hd) {
	html {
		@include font-size($font-size-m);
	}
}
