@import '../../../../../sass/utilites/variables';
@import '../../../../../sass/utilites/mixins';

.quiz {
	:global {
		.tooltip {
			transform: translateY(- rem(1px));
			margin-left: rem(5px);
			svg {
				width: rem(18px);
				height: rem(18px);
			}
		}
	}
	strong, b {
		font-weight: 500;
	}
}

.checkboxLabel {
	@include font-size($font-size-base);

}

.checkbox {
	width: 100%;
	max-width: rem(980px);
}

.note {
	@include font-size($font-size-m);
	svg {
		margin-top: - rem(2px);
		color: $gray-500;
	}
}

.upload, .document {
	@include font-size($font-size-m);
	background-color: $gray-400;
	border-radius: $border-radius-xl;

	max-width: 100%;

	@include media-breakpoint-up(xl) {
		padding: rem(17px);
		height: rem(70px);
		width: rem(300px);
	}
	@include media-breakpoint-down(xl) {
		padding: rem(12px);
	}
}

.upload {
	@include transition(background-color);
	cursor: pointer;
	color: get($colors, dark);
	user-select: none;
	pointer-events: none;
	&:hover {
		//color: get($colors, primary);
	}
}

.sectionEditable {
	.upload {
		pointer-events: auto;
		color: get($colors, primary);
	}
}

.uploadIsLoading {
	position: relative;
	.uploadIcon, .uploadLabel {
		visibility: hidden;
	}
}

.document {
	position: relative;
	&Delete {
		@include transition(color);
		position: absolute;
		right: 0;
		top: 0;
		color: $gray-500;
		padding: rem(5px) rem(8px);
		&:hover {
			color: get($colors, danger);
		}
	}
}

.documentIcon {
	width: rem(34px);
	height: rem(34px);
	border-radius: 50%;
	background-color: rgba(get($colors, primary), .1);
	color: get($colors, primary);
	svg {
		width: 50%;
		height: 50%;
	}
}

.fileName {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.section {
	& + .section {
		margin-top: rem(60px);
	}
}

.uploadedDocuments {
	position: relative;
	padding-bottom: rem(3px);
}

.uploadedDocumentsIsInvalid {
	color: get($colors, danger);
	.upload {
		color: inherit;
		outline: $border-width solid get($colors, danger);
	}
}

.uploadedDocumentsError {
	position: absolute;
	bottom: 0;
	left: 0;
}
