@import '../../../../../sass/utilites/variables';
@import '../../../../../sass/utilites/mixins';

.formGroup {
	input {
		&[readonly] {
			pointer-events: none;
			color: get($colors, dark);
		}
	}
	a {
		color: get($colors, primary);
		&:hover {
			color: get($colors, secondary);
			text-decoration: underline;
		}
	}
}

.buttonsRow {
	overflow: hidden;
	padding: $border-width;
}







