@import '../../../../../../../sass/utilites/variables';
@import '../../../../../../../sass/utilites/mixins';


.edit {
	@include transition(color);
	color: get($colors, secondary);
	@include media-breakpoint-down(sm) {
		position: absolute;
		left: 0;
		top: 0;
		z-index: get($index, default);
	}
	&:hover {
		color: get($colors, primary);
	}
}
